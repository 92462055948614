import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingOverlay, Modal } from '@mantine/core';
import { auth } from 'api/firebase';
import Button from 'components/Button';
import dayjs from 'dayjs';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { Margin, usePDF } from 'react-to-pdf';
import { useGetSessionDetailsQuery } from 'store/apis/payment';
import {
  useGetMyProfileQuery,
  useLazyGetMyProfileWithTokenQuery,
} from 'store/apis/profile';
import { selectAccessToken } from 'store/Slice/authSlice';
import cn from 'utils/cn';
import Download from './assets/download.svg';
import { useToImage } from '@hcorta/react-to-image';

const PaymentConfirmation = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const [open, setOpen] = React.useState(true);

  const { data: mtyProfileData, isLoading: loading } = useGetMyProfileQuery();

  const { checkProfileAndNavigate } = useFirebaseLogin();
  const accessToken = useSelector(selectAccessToken);

  const [getUserWithToken, getUserWithTokenRes] =
    useLazyGetMyProfileWithTokenQuery();

  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: 'page.pdf',
    page: { margin: Margin.SMALL },
  });

  const {
    ref: receiptRef,
    isLoading: isReceiptDownloading,
    getSvg,
    dataURL,
    getJpeg,
    getBlob,
  } = useToImage();

  const sessionId = queryParams.get('sessionId');

  const { data: paymentData, isLoading } = useGetSessionDetailsQuery(
    sessionId,
    {
      skip: !sessionId,
    },
  );

  // console.log(paymentData?.data);

  const data = React.useMemo(
    () => [
      {
        title: 'Package',
        value:
          paymentData?.data?.details?.subscriptionDetails?.[0]?.product?.name,
      },
      {
        title: 'User',
        value:
          paymentData?.data?.userInfo?.user?.name +
          ' ' +
          paymentData?.data?.userInfo?.user?.lastname,
      },
      { title: 'References Number', value: sessionId?.slice(0, 12) + '...' },
      {
        title: 'Date',
        value: dayjs(paymentData?.data?.timestamp).format('MMM DD, YYYY'),
      },
      {
        title: 'Time',
        value: dayjs(paymentData?.data?.timestamp).format('hh:mm A'),
      },
      //   { title: 'Payment Method', value: 'Credit Card' },
    ],
    [paymentData, sessionId],
  );

  const handleNext = async () => {
    localStorage.clear();
    const customToken = paymentData?.data?.userInfo?.customToken;
    const user = paymentData?.data?.userInfo?.user;
    if (accessToken && mtyProfileData?.data && user?.type === 'student') {
      checkProfileAndNavigate(mtyProfileData?.data, accessToken);
      return;
    }

    if (customToken && user?.type === 'student') {
      await auth.signInWithCustomToken(customToken);
      const token = await auth.currentUser.getIdToken();
      localStorage.setItem('token', token);

      checkProfileAndNavigate(user, token, () => {
        navigate('/complete-profile');
      });
      return;
    }

    /*  if (!user?.dateOfBirth && user?.phone) {
      navigate('/birth?phoneNumber=' + user?.phone);
      return;
    }

    if (!user?.dateOfBirth && !user?.phone) {
      navigate('/birth');
      return;
    } */

    /*   if (!user?.isPhoneVerified && user?.type !== 'student') {
      navigate(
        '/phone-validation?birthDate=' +
          dayjs(user?.dateOfBirth).format('YYYY-MM-DD'),
      );
      return;
    } */

    if (!customToken) {
      const query = queryString.stringify({
        phone: user?.phone,
        birthDate: dayjs(user?.dateOfBirth).format('YYYY-MM-DD'),
      });
      navigate('/over13?' + query);
      return;
    }

    if (customToken && !user?.email) {
      const query = queryString.stringify({
        phone: user?.phone,
        birthDate: user?.dateOfBirth
          ? dayjs(user?.dateOfBirth).format('YYYY-MM-DD')
          : undefined,
      });
      navigate('/over13?' + query);
      return;
    }

    if (customToken) {
      await auth.signInWithCustomToken(customToken);
      const token = await auth.currentUser.getIdToken();
      localStorage.setItem('token', token);
      const userData = await getUserWithToken(token).unwrap();
      const user = userData?.data;
      const wthAddOn = user?.activeSubscriptions?.some(
        (sub) =>
          sub?.status === 'active' && sub?.product?.name?.includes('Add-On'),
      );

      checkProfileAndNavigate(
        user,
        token,
        () => {
          navigate('/complete-profile');
        },
        {
          showWelcomeModal: 'true',
          modalType:
            user?.type === 'parent'
              ? wthAddOn
                ? 'parent-addon'
                : 'parent-no-addon'
              : 'educator-premium',
        },
      );

      /*   if (userData?.data?.type === 'parent') {
        checkProfileAndNavigate(
          user,
          token,
          () => {
            navigate('/complete-profile');
          },
          {
            showWelcomeModal: 'true',
            modalType:
              user?.type === 'parent'
                ? wthAddOn
                  ? 'parent-addon'
                  : 'parent-no-addon'
                : 'educator-premium',
          },
        );
      } else {
        checkProfileAndNavigate(
          user,
          token,
          () => {
            navigate('/complete-profile');
          },
          {},
        );
      } */

      checkProfileAndNavigate(
        user,
        token,
        () => {
          navigate('/complete-profile');
        },
        {
          showWelcomeModal: 'true',
          modalType:
            user?.type === 'parent'
              ? wthAddOn
                ? 'parent-addon'
                : 'parent-no-addon'
              : 'educator-premium',
        },
      );
      return;
    }
    if (paymentData?.data) {
      const phone = paymentData?.data?.userInfo?.user?.phone;
      const birthDate = paymentData?.data?.userInfo?.user?.birthDate;
      navigate(`over13?phone=${phone}&birthDate=${birthDate}`);
      return;
    }
    navigate('/over13');
  };

  const downloadReceiptToImage = async () => {
    await getJpeg();
  };

  return (
    <Modal
      opened={open}
      centered
      withCloseButton={false}
      closeOnClickOutside
      size={'35rem'}
      onClose={() => {}}
    >
      <>
        <div>
          <div className="flex items-center w-full bg-white" ref={receiptRef}>
            <div className="bg-white p-2 rounded-lg w-full mx-auto">
              {/* <img
              src={ConfirmationIcon}
              alt=""
              className="size-16 xl:size-20 2xl:size-24 mx-auto"
            /> */}

              <img
                src={'/logo.png'}
                alt="g3ms"
                className={cn(
                  'w-24 h-16 md:w-28 md:h-20  xl:w-32 xl:h-24 mx-auto  mt-4 object-contain',
                )}
              />

              <header>
                <h2 className="text-center mx-auto mt-4 font-semibold text-xl xl:text-2xl 2xl:text-3xl">
                  Payment Confirmed!
                </h2>
              </header>

              <hr className="border-t-2 border-[#DCDEE0] my-8 border-dashed " />

              <main>
                <div className="grid gap-4 py-4">
                  {data?.map((item, _index) => (
                    <div key={_index} className="flex justify-between gap-4">
                      <h4 className="text-[#707070]">{item?.title}</h4>
                      <span className="text-[#121212] font-medium">
                        {item?.value}
                      </span>
                    </div>
                  ))}
                </div>

                <hr />

                <div className="flex justify-between gap-4 py-4">
                  <h4>Amount</h4>
                  <span>${paymentData?.data?.amountTotal / 100}</span>
                </div>
              </main>
            </div>
          </div>
          <hr />

          <button
            className="flex items-center justify-center font-medium w-full gap-2 bg-[#F9FAFB] border border-[#DCDEE0] rounded-lg py-3 px-4 mt-4"
            onClick={downloadReceiptToImage}
          >
            <img
              src={Download}
              alt=""
              className="size-4 xl:size-5 2xl:size-6"
            />
            Get PDF Receipt
          </button>

          <Button
            className="w-full mt-4 max-w-full "
            variant={'primary'}
            onClick={() => {
              handleNext();
            }}
            isLoading={isLoading || loading || getUserWithTokenRes.isLoading}
          >
            Continue
          </Button>
        </div>
      </>
      <LoadingOverlay
        visible={isLoading || loading || getUserWithTokenRes.isLoading}
      />
    </Modal>
  );
};

export default PaymentConfirmation;
