import { useDispatch } from 'react-redux';

import { auth } from 'api/firebase';
import useModal from 'components/Modal/hook/useModal';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import {
  useLazyGetMyProfileQuery,
  useLazyGetMyProfileWithTokenQuery,
} from 'store/apis/profile';
import { setUserDetails } from 'store/Slice/authSlice';
import analytics from 'utils/analytics';
import useHandleUserNavigation from './useHandleUserNavigation';
import { useEffect } from 'react';
import { setIsLoading } from 'store/Slice/uiSlice';
import { apiSlice } from 'store/apis';

export default function useFirebaseLogin() {
  const [getMyProfile, getMyProfileResponse] = useLazyGetMyProfileQuery();
  const [getMyProfileWithToken, getMyProfileWithTokenResponse] =
    useLazyGetMyProfileWithTokenQuery();

  const dispatch = useDispatch();
  const { openModal } = useModal();

  const navigation = useNavigate();

  const { loginUser } = useHandleUserNavigation();

  useEffect(() => {
    dispatch(
      setIsLoading(
        getMyProfileResponse.isLoading ||
          getMyProfileWithTokenResponse.isLoading,
      ),
    );
  }, [getMyProfileResponse, getMyProfileWithTokenResponse]);

  const checkProfileAndNavigate = async (
    userData,
    token,
    nextNavigation,
    queries,
  ) => {
    try {
      const res = await getMyProfileWithToken(token).unwrap();

      const user = res?.data || userData;

      console.log('user gf', user);
      dispatch(
        setUserDetails({
          user,
          accessToken: token,
        }),
      );

      dispatch(apiSlice.util.resetApiState());

      if (queries?.redirectTo === 'codeToEarn') {
        loginUser(user, token, queries);
        return;
      }

      if (user?.type !== 'student' && queries?.redirectTo?.includes('plans')) {
        loginUser(user, token, queries);
        return;
      }

      console.log(
        'navigating to user',
        user?.parentConfirmations?.parentConfirmationForChildSignup,
      );

      if (user.isUserUpgradedPlan) {
        loginUser(user, token, queries);
        return;
      }

      if (
        user?.completedProfile &&
        user?.type !== 'student' &&
        !user?.isUserUpgradedPlan
      ) {
        if (
          user?.type === 'private_tutor' ||
          user?.type === 'tutor' ||
          user?.type === 'teacher' ||
          user?.type === 'parent' ||
          user?.type === 'homeschool_parent_esa'
        ) {
          openModal('ParentPaymentWarning');
          return;
        }

        if (
          user?.type === 'school_leader' ||
          user?.type === 'district_leader'
        ) {
          openModal('LeadersPaymentWarning');
          return;
        }

        if (user?.type !== 'student') {
          openModal('TeacherPaymentWarning');
          return;
        }

        return;
      }

      if (user?.completedProfile) {
        analytics.logEvent('login', {
          userId: user.id,
          email: user.email,
        });

        if (user?.type === 'student' && !user?.isUserUpgradedPlan) {
          openModal('StudentPaymentWarning');

          dispatch(
            setUserDetails({
              user,
              accessToken: token,
            }),
          );

          loginUser(user, token, queries);

          NotificationManager.success(
            'Login Success',
            'You have successfully logged in.',
            2000,
          );

          return;
        }

        /*   if (user?.isUnder13 && !user?.isUserUpgradedPlan) {
          openModal('StudentPaymentWarning');

          dispatch(
            setUserDetails({
              user,
              accessToken: token,
            }),
          );

          loginUser(user, token, queries);

          NotificationManager.success(
            'Login Success',
            'You have successfully logged in.',
            2000,
          );

          return;
        }

        if (!user?.isUnder13 && !user?.isUserUpgradedPlan) {
          openModal('StudentPaymentWarning');

          dispatch(
            setUserDetails({
              user,
              accessToken: token,
            }),
          );

          loginUser(user, token, queries);

          NotificationManager.success(
            'Login Success',
            'You have successfully logged in.',
            2000,
          );

          return;
        } */

        dispatch(
          setUserDetails({
            user,
            accessToken: token,
          }),
        );

        loginUser(user, token, queries);

        NotificationManager.success(
          'Login Success',
          'You have successfully logged in.',
          2000,
        );

        return;
      }

      if (nextNavigation) {
        nextNavigation?.();
        return;
      }

      /*   if (user?.type.toLowerCase() === 'student' && !user.email) {
      // go to email verification

      return;
    } */

      openModal('AccountCreatedModal', {
        onContinue: () => {
          navigation('/complete-profile', {
            replace: true,
          });
        },
      });
    } catch (error) {
      console.log(error, 'error');
      openModal('WarningModal', {
        title: 'Login Failed',
        description: 'Failed to get user details.',
        onContinue: () => {
          auth.signOut();
          navigation('/login', {
            replace: true,
          });
        },
      });
    }
  };

  const loginWithFirebase = async (type) => {
    try {
      const accToken = await auth?.currentUser?.getIdToken();
      if (!accToken) {
        NotificationManager.error(
          'Login Failed',
          'Failed to get access token.',
          2000,
        );
        return;
      }

      const res = await getMyProfile().unwrap();

      const user = res?.data;

      if (!user) {
        openModal('WarningModal', {
          title: 'Login Failed',
          description:
            'There was no user found with this email. Please sign up.',
        });

        NotificationManager.error(
          'Login Failed',
          'Failed to get user details.',
          2000,
        );
        return;
      }

      if (type === 'login') {
        studentLogin(user, accToken);
        return;
      }

      await checkProfileAndNavigate(user, accToken);

      analytics.logEvent('login', {
        userId: user.id,
        email: user.email,
      });
    } catch (err) {
      console.log(err, 'err');

      analytics.logEvent('login_error', {
        error: err,
      });

      const message = Array.isArray(err?.data?.message)
        ? err?.data?.message[0]
        : err?.data?.message;

      openModal('WarningModal', {
        title: 'Login Failed',
        description: message || 'Failed to get access token.',
      });

      NotificationManager.error(
        'Login Failed',
        message || 'Failed to get access token.',
        2000,
      );
    }
  };

  const studentLogin = async (user, token) => {
    dispatch(
      setUserDetails({
        user,
        accessToken: token,
      }),
    );

    if (!user?.completedProfile) {
      openModal('AccountCreatedModal', {
        onContinue: () => {
          navigation('/complete-profile', {
            replace: true,
          });
        },
      });
      return;
    }

    loginUser(user, token);
  };

  return {
    loginWithFirebase,
    isLoading: getMyProfileResponse.isLoading,
    checkProfileAndNavigate,
    studentLogin,
  };
}
