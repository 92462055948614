import { LoadingOverlay, Portal } from '@mantine/core';
import { Box } from '@mui/material';
import { useQuery } from 'api/hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  setJoinedSchoolAssignment,
  setSchoolAssignmentResult,
  setSchoolAssignmentStep,
  setTimerDurationSecs,
} from 'store/Slice/schoolAssignmentSlice';
import {
  useJoinNonPinSchoolAssignmentMutation,
  useLazyGetSchoolAssignmentResultQuery,
  useLazyGetTodayAssignmentsQuery,
} from 'store/apis/assignments';
import { useAppDispatch, useAppSelector } from 'store/store';
import SchoolAssignmentQuiz from '../Layout/schoolAssignmentChallenge';
import EnterPin from './EnterPinCode';
import Waiting from './Waiting';
import Completed from './completed';
import Timeout from './timeout';

export default function PinCode() {
  const dispatch = useAppDispatch();
  const query = useQuery();

  const {
    joinedSchoolAssignment,
    selectedAssignment,
    step: stepValue,
    status,
  } = useAppSelector((state) => state.schoolAssignmentReducer);

  const assignment = query.get('assignment');
  const queryStep = query.get('step') ? parseInt(query.get('step')) : 0;
  const step = stepValue ?? queryStep;

  const [joinAssignmentReq, joinAssignmentRes] =
    useJoinNonPinSchoolAssignmentMutation();
  const [getDueTodaySchoolAssignmentsReq, getDueTodaySchoolAssignmentsRes] =
    useLazyGetTodayAssignmentsQuery();

  const [getAssignmentResultReq, getAssignmentResultRes] =
    useLazyGetSchoolAssignmentResultQuery();

  const questions = joinedSchoolAssignment?.session?.currentTemplateQuestions;

  const [selectedAnswers, setSelectedAnswers] = useState([]);

  function setStep(step) {
    dispatch(setSchoolAssignmentStep(step));
  }

  useEffect(() => {
    if (questions?.length > 0 && step === 1 && status === 'Start') {
      const initialAnswers = questions.map((question) => ({
        questionId: question.id,
        studentAnswer: '',
      }));
      console.log('initialAnswers', initialAnswers);
      setSelectedAnswers(initialAnswers);
    }
  }, [step, questions, status]);

  const { user } = useAppSelector((state) => state.authReducer);

  const navigate = useNavigate();

  useEffect(() => {
    const initAssignment = async () => {
      try {
        const res = await getAssignmentResultReq({
          id: assignment,
          userId: user?.id,
        }).unwrap();
        console.log('getAssignmentResultReq', res);
        dispatch(setSchoolAssignmentResult(res?.data));
        dispatch(setSchoolAssignmentStep(3));
      } catch (error) {
        console.log('error in getSchoolAssignmentResult', error);
        const dueTodayAssignments = await getDueTodaySchoolAssignmentsReq(
          user?.id,
        ).unwrap();

        console.log('dueTodayAssignments', dueTodayAssignments);

        const findAssignment = dueTodayAssignments?.data?.find(
          (assig) => assig.id === assignment,
        );
        console.log('findAssignment', findAssignment);
        if (findAssignment) {
          if (findAssignment.pinCode) {
            dispatch(setSchoolAssignmentStep(stepValue));
          } else {
            try {
              console.log('joinAssignmentReq', joinAssignmentReq);
              const res = await joinAssignmentReq({
                id: assignment,
                userId: user?.id,
              }).unwrap();
              console.log('awaitjoinAssignmentReq', res?.data);
              dispatch(setJoinedSchoolAssignment(res?.data));
              const timeDuration = findAssignment.timeDuration * 60;
              dispatch(setTimerDurationSecs(timeDuration));
              dispatch(setSchoolAssignmentStep(4));
            } catch (error) {
              console.log('error in joinAssignmentReq', error);
            }
          }
        } else {
          navigate('/student/dashboard');
        }
      }
    };
    if (assignment) {
      initAssignment();
    }
  }, [assignment, user?.id]);

  /*  useEffect(() => {
    dispatch(
      getSchoolAssignmentResult({
        id: assignment,
        userId: user?.id,
      }),
    )
      .unwrap()
      .then((response) => {
        dispatch(setSchoolAssignmentStep(3));
      })
      .catch((err) => {
        console.log('err in getSchoolAssignmentResult', err);
        dispatch(getDueTodaySchoolAssignments(user?.id))
          .unwrap()
          .then((data) => {
            for (const ass of data) {
              if (ass.id === assignment) {
                if (!ass.pinCode) {
                  dispatch(
                    joinNonPinSchoolAssignment({
                      id: ass.id,
                      userId: user?.id,
                    }),
                  )
                    .unwrap()
                    .then((response) => {
                      const timeDuration = ass.timeDuration * 60;
                      dispatch(setTimerDurationSecs(timeDuration));
                      dispatch(setSchoolAssignmentStep(4));
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  break;
                } else {
                  // dispatch(setSchoolAssignmentStep(0));
                }
              }
            }
          })
          .catch((err) => {
            console.error('error in getDue', err);
          });
      });
  }, [assignment, user?.id]); */

  const isLoading =
    joinAssignmentRes.isLoading ||
    getDueTodaySchoolAssignmentsRes.isLoading ||
    getAssignmentResultRes.isLoading;

  if (isLoading)
    return (
      <Portal>
        <LoadingOverlay
          visible={
            joinAssignmentRes.isLoading ||
            getDueTodaySchoolAssignmentsRes.isLoading ||
            getAssignmentResultRes.isLoading
          }
        />
      </Portal>
    );

  return (
    <Box height="100%" bgcolor={'#5C5C5C'} borderRadius={'20px'} width={'100%'}>
      <Box
        justifyContent={'center'}
        display={'flex'}
        alignItems={'center'}
        height={'100%'}
        borderRadius={'18px'}
        width={'100%'}
      >
        {step === 0 && <EnterPin setStep={setStep} />}
        {step === 1 && <Waiting status={status} setStep={setStep} />}
        {step === 2 && <Timeout setStep={setStep} />}
        {step === 3 && <Completed setStep={setStep} />}
        {step === 4 && (
          <SchoolAssignmentQuiz
            setStep={setStep}
            selectedAnswers={selectedAnswers}
            setSelectedAnswers={setSelectedAnswers}
          />
        )}
      </Box>
      <Portal>
        <LoadingOverlay
          visible={
            joinAssignmentRes.isLoading ||
            getDueTodaySchoolAssignmentsRes.isLoading ||
            getAssignmentResultRes.isLoading
          }
        />
      </Portal>
    </Box>
  );
}
