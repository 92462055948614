import config from 'configs/config';
import queryString from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyGetMyProfileWithTokenQuery } from 'store/apis/profile';

const ADMIN_ROLES = [
  'teacher',
  'private_tutor',
  'tutor',
  'homeschool_parent_esa',
  'school_leader',
  'learning_coach',
  'parent',
];

export default function useHandleUserNavigation() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');
  const navigate = useNavigate();
  const [getPProfileByToken] = useLazyGetMyProfileWithTokenQuery();

  const redirectToAdmin = (queryParams) => {
    localStorage.clear();
    window.location.href = `${config.ADMIN_URL}/token-login?${queryParams}`;
  };

  const loginUser = async (user, token, queries = {}) => {
    const queryParams = queryString.stringify({ ...queries, token });
    if (!user) {
      const res = await getPProfileByToken(token).unwrap();
      user = res?.data;
    }

    if (user.type === 'student') {
      if (redirectTo === 'codeToEarn') {
        navigate('/app/student/challenge-selection');
      } else {
        navigate('/app/student');
      }
      return;
    }

    if (
      user.type === 'parent' &&
      (queries.redirectTo === 'plans' || queries.redirectTo === '/plans')
    ) {
      navigate('/plans?redirectTo=parent');
      return;
    }

    if (ADMIN_ROLES.includes(user.type)) {
      redirectToAdmin(queryParams);
      return;
    }

    redirectToAdmin(queryParams); // Default case
  };

  return { loginUser };
}
