import Lottie from 'lottie-react';
import React from 'react';
import AnimatedLogo from 'asset/Bot-lottie1-CL-V1.json';

export default function SidebarFooter() {
  return (
    <div className="w-full flex flex-col items-center justify-center gap-5">
      <p className="text-sm font-semibold text-[#979C9E] text-center">
        © <span className="text-[#C73E9D]">G3MS.</span> All Right Reserved
      </p>
      <Lottie className=" h-45" animationData={AnimatedLogo}></Lottie>
    </div>
  );
}
