import { Accordion, Collapse, HoverCard } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { nanoid } from '@reduxjs/toolkit';

import React from 'react';
import { FaChevronUp } from 'react-icons/fa';
import { useMatch } from 'react-router-dom';
import cn from 'utils/cn';

const statusMap = {
  inprogress: {
    text: 'In Progress',
    color: '#6941C6',
    borderColor: '#E9D7FE',
    backgroundColor: '#F9F5FF',
    circleBackground: '#6941C6',
  },
  Started: {
    text: 'In Progress',
    color: '#6941C6',
    borderColor: '#E9D7FE',
    backgroundColor: '#F9F5FF',
    circleBackground: '#6941C6',
  },
  Completed: {
    text: 'Done',
    color: '#067647',
    borderColor: '#ABEFC6',
    backgroundColor: '#ECFDF3',
    circleBackground: '#17B26A',
  },
  todo: {
    text: 'To Do',
    color: '#B54708',
    borderColor: '#FEDF89',
    backgroundColor: '#FFFAEB',
    circleBackground: '#B54708',
  },
  Not_Started: {
    text: 'To Do',
    color: '#B54708',
    borderColor: '#FEDF89',
    backgroundColor: '#FFFAEB',
    circleBackground: '#B54708',
  },
  Live: {
    text: 'Live',
    color: '#6941C6',
    borderColor: '#E9D7FE',
    backgroundColor: '#F9F5FF',
    circleBackground: '#6941C6',
  },
  Pause: {
    text: 'Pause',
    color: '#FF0000',
    borderColor: '#FF000050',
    backgroundColor: '#F3494920',
    circleBackground: '#FF0000',
  },
  Resume: {
    text: 'Resume',
    color: '#067647',
    borderColor: '#ABEFC6',
    backgroundColor: '#ECFDF3',
    circleBackground: '#17B26A',
  },
  Cancelled: {
    text: 'Cancelled',
    color: '#FF0000',
    borderColor: '#FF000050',
    backgroundColor: '#F3494920',
    circleBackground: '#FF0000',
  },
};

const RenderAccordion = ({
  items = [],
  onItemClick,
  icon,
  title,
  id,
  status,
  count,
  onHeaderClick,
  disabled,
}) => {
  const [opened, { toggle }] = useDisclosure(false);
  const queryParams = new URLSearchParams(window.location.search);
  const assignmentId = queryParams.get('assignment');
  const buttonRef = React.useRef(null);

  const videoId = queryParams.get('videoId');

  if (!Array.isArray(items)) return null;

  return (
    <div>
      <button
        className={cn(
          'grid grid-cols-[1fr,auto] items-center gap-4 py-2 ml-2 justify-between w-full',
          {
            'bg-[#20A58A20]': assignmentId === id,
          },
        )}
        onClick={() => {
          onHeaderClick?.();
          toggle?.();
        }}
      >
        <div className="flex items-center gap-4">
          {icon}
          <div className="grid justify-between items-center pr-2">
            <span className="text-[#252B37] font-semibold text-sm line-clamp-1">
              {title}
            </span>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {count || count === 0 ? (
            <div className="border border-[#E9EAEB] text-[#414651] font-semibold grid place-items-center py-1 px-2 rounded-full w-10 h-6 text-xs">
              <span>{count <= 99 ? count : '99+'}</span>
            </div>
          ) : null}
          {status ? (
            <div
              className="border border-[#ABEFC6] text-[#067647] bg-[#ECFDF3] font-medium grid grid-cols-[auto,1fr] gap-2 items-center py-1 px-2 rounded-full min-w-14 h-6 text-[0.625rem]"
              style={{
                borderColor: statusMap[status]?.borderColor,
                backgroundColor: statusMap[status]?.backgroundColor,
              }}
            >
              <div
                className="size-2 rounded-full bg-[#17B26A]"
                style={{
                  backgroundColor: statusMap[status]?.circleBackground,
                }}
              ></div>
              <span
                className="mx-auto"
                style={{
                  color: statusMap[status]?.color,
                }}
              >
                {statusMap[status]?.text}
              </span>
            </div>
          ) : (
            <FaChevronUp
              className={cn(
                'text-[#A4A7AE] text-xs transition-all duration-150 ease-in-out',
                {
                  'transform rotate-180': opened,
                },
              )}
            />
          )}
        </div>
      </button>
      <Collapse in={opened} className="pl-2 pr-2">
        {items?.map((item) => {
          if (item.subItems && item.subItems.length) {
            return (
              <RenderAccordion
                items={item.subItems}
                icon={item?.icon}
                title={item.title}
                id={item.id}
                status={item.status}
                count={item.count}
                onItemClick={onItemClick}
                key={item.id}
                navigateOnClickHeader={item.navigateOnClickHeader}
                onHeaderClick={() => {
                  onHeaderClick?.(item);
                }}
                disabled={item.disabled}
              />
            );
          }
          return (
            <HoverCard zIndex={9999999999999999999} width={300} offset={0}>
              <HoverCard.Target>
                <button
                  ref={buttonRef}
                  className={cn(
                    'grid items-center gap-4 py-2 pl-2 pr-2 justify-between w-full',
                    {
                      'bg-[#20A58A20]':
                        assignmentId === item?.id ||
                        videoId === item?.videoInfo?.id,
                      'grid-cols-[1fr,auto]': item?.status,
                    },
                  )}
                  // disabled={item?.disabled}
                  key={item?.id}
                  onClick={(e) => {
                    if (item?.disabled) {
                      e.preventDefault();
                      return;
                    }

                    onItemClick?.(item);
                  }}
                >
                  <span className="text-[#252B37] font-semibold text-sm w-full line-clamp-1">
                    {item?.title?.trim()}
                  </span>

                  {item?.status && (
                    <div
                      className="border border-[#ABEFC6] text-[#067647] bg-[#ECFDF3] font-medium grid grid-cols-[auto,1fr] gap-2 items-center py-1 px-2 rounded-full min-w-14 h-6 text-[0.625rem]"
                      style={{
                        borderColor: statusMap[item?.status]?.borderColor,
                        backgroundColor:
                          statusMap[item?.status]?.backgroundColor,
                      }}
                    >
                      <div
                        className="size-2 rounded-full bg-[#17B26A]"
                        style={{
                          backgroundColor:
                            statusMap[item?.status]?.circleBackground,
                        }}
                      ></div>
                      <span
                        className="mx-auto"
                        style={{
                          color: statusMap[item?.status]?.color,
                        }}
                      >
                        {statusMap[item?.status]?.text}
                      </span>
                    </div>
                  )}
                </button>
              </HoverCard.Target>
              {item?.templateInfo?.skill[0]?.skill?.name ||
              item?.videoInfo?.skill[0]?.skill?.name ? (
                <HoverCard.Dropdown
                  classNames={{
                    dropdown: '!shadow-xl !rounded-xl',
                  }}
                >
                  <div>
                    {item?.templateInfo?.skill[0]?.skill?.name ||
                      item?.videoInfo?.skill[0]?.skill?.name}
                  </div>
                </HoverCard.Dropdown>
              ) : null}
            </HoverCard>
          );
        })}
      </Collapse>
    </div>
  );
};

const AssignmentAccordion = React.memo(RenderAccordion);

export default AssignmentAccordion;
