import {
  appleProvider,
  auth,
  classLinkProvider,
  googleProvider,
} from 'api/firebase';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'store/Slice/authSlice';

const useSocialSignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  async function googleSignIn(cb) {
    try {
      if (auth.currentUser) {
        dispatch(logoutUser());
      }
      const res = await signInWithProvider(googleProvider);
      console.log('success google sign in', res);
      if (cb) {
        cb({ success: true, user: res });
      } else {
        return res;
      }
    } catch (error) {
      console.log('error google sign in', error);
      if (cb) {
        cb({ success: false, error });
      } else {
        return error;
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function appleSignIn(cb) {
    try {
      if (auth.currentUser) {
        dispatch(logoutUser());
      }
      const res = await signInWithProvider(appleProvider);
      if (cb) {
        cb({ success: true, user: res });
      } else {
        return res;
      }
    } catch (error) {
      if (cb) {
        cb({ success: false, error });
      } else {
        return error;
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function classLinkSignIn(cb) {
    try {
      if (auth.currentUser) {
        dispatch(logoutUser());
      }
      const res = await signInWithProvider(classLinkProvider);
      if (cb) {
        cb({ success: true, user: res });
      } else {
        return res;
      }
    } catch (error) {
      if (cb) {
        cb({ success: false, error });
      } else {
        return error;
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function signInWithProvider(provider) {
    setIsLoading(true);

    if (auth.currentUser) {
      dispatch(logoutUser());
    }

    const allCookies = Cookies.get();

    for (const cookie in allCookies) {
      Cookies.remove(cookie);
    }

    const user = await auth.signInWithPopup(provider);
    setIsLoading(false);
    return user;
  }

  return {
    googleSignIn,
    appleSignIn,
    classLinkSignIn,
    isLoading,
  };
};

export default useSocialSignIn;
