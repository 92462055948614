import React from 'react';
import { useGetUserAssignmentsQuery } from 'store/apis/assignments';
import RenderAssignments from './RenderAssignments';

export default function DailyPracticeAssignments({ toggleNavVisibility }) {
  const { data, isLoading, refetch } = useGetUserAssignmentsQuery();

  return (
    <div className="px-4 py-2 rounded-2xl border border-black/20">
      <RenderAssignments
        title={'Daily Practice Assignments'}
        total={data?.data?.dailyPracticeAssignments?.totalCount}
        topics={data?.data?.dailyPracticeAssignments?.topics}
        type={'dailyPractice'}
        toggleNavVisibility={toggleNavVisibility}
        onRefetch={refetch}
      />
      <RenderAssignments
        title={'School Assignments'}
        total={data?.data?.schoolAssignments?.totalCount}
        topics={data?.data?.schoolAssignments?.topics}
        type={'school'}
        toggleNavVisibility={toggleNavVisibility}
        onRefetch={refetch}
        navigateOnClickHeader
      />
    </div>
  );
}
