import React from 'react';
import Skills from 'asset/dashboard/skills.png';
import TotalEarned from 'asset/dashboard/total-earned.png';
import Video from 'asset/dashboard/video.png';
import { useGetUserAssignmentsQuery } from 'store/apis/assignments';

export default function UserAssignmentInfo() {
  const { data, isLoading } = useGetUserAssignmentsQuery();
  return (
    <div className={'SideBarFooter px-4'}>
      <p className="text-base px-0 font-medium text-[#C73E9D]">
        The more your learn,{' '}
        <span className="text-[#131515]">the more you earn!</span>
      </p>
      <div className="">
        <div className="w-full flex items-center justify-between gap-5 mb-3 mt-4">
          <div className="flex items-center gap-2">
            <img className="size-7" src={Skills} alt="" />
            <p className="text-sm font-medium text-black">
              Skills <span className="text-[#C73E9D]">Mastered</span>
            </p>
          </div>
          <span className="text-sm font-semibold text-[131515]">
            {data?.data?.userInfo?.totalMasteredSkills ?? 0}
          </span>
        </div>
        <div className="w-full flex items-center justify-between gap-5 mb-3 mt-4">
          <div className="flex items-center gap-2">
            <img className="size-7" src={Video} alt="" />
            <p className="text-sm font-medium text-black">
              Videos <span className="text-[#C73E9D]">Watched</span>
            </p>
          </div>
          <span className="text-sm font-semibold text-[131515]">
            {data?.data?.userInfo?.totalWatchTime ?? 0}
          </span>
        </div>
        <div className="w-full flex items-center justify-between gap-5 mb-3 mt-4">
          <div className="flex items-center gap-2">
            <img className="size-7" src={TotalEarned} alt="" />
            <p className="text-sm font-medium text-black">
              G3MS Token <span className="text-[#C73E9D]">Earned</span>
            </p>
          </div>
          <span className="text-sm font-semibold text-[131515]">
            {data?.data?.userInfo?.gemsTokens ?? 0}
          </span>
        </div>
      </div>
    </div>
  );
}
