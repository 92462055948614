import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { store } from '../store';

import { update } from '@intercom/messenger-js-sdk';
import { auth } from 'api/firebase';
import config from 'configs/config';
import Cookies from 'js-cookie';
import { redirect } from 'react-router-dom';
import { logoutUser, setAccessToken } from 'store/Slice/authSlice';
import analytics from 'utils/analytics';
import { getDeviceType } from 'utils/lib';

const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL
    ? `${config.BASE_URL}api/v1/`
    : 'https://dev.g3ms.co/api/v1/',
  prepareHeaders: async (headers, api) => {
    const accessToken = store.getState().authReducer.accessToken;

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const handleLogout = () => {
  localStorage.removeItem('userData');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('uuid');
  localStorage.removeItem('header');

  store.dispatch(logoutUser());

  store.dispatch(apiSlice.util.resetApiState());
  analytics.logEvent('logout', { user_id: localStorage.getItem('uuid') });
  analytics.logoutUser();
  redirect('/');
};

async function baseQueryWithReAuth(args, api, extraOptions) {
  try {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error) {
      analytics.apiError({ error: result?.error || 'Error', api, args });
    } else {
      analytics.logEvent('api_response', { response: result, api, args });
    }

    // console.log(result, 'result');
    const isInvalidToken = result?.error?.data?.code === 'INVALID_TOKEN';
    const isUnAuthorized = result?.error?.data?.code === 'UNAUTHORIZED';
    const statusCode = result?.meta?.response?.status;

    console.log('isInvalidToken', isInvalidToken, statusCode);

    if (isInvalidToken || isUnAuthorized || statusCode === 401) {
      const accessToken = await auth.currentUser?.getIdToken();
      console.log('accessToken', accessToken);
      store.dispatch(setAccessToken(accessToken));

      const newResult = await baseQuery(args, api, extraOptions);

      const publicPaths = [
        '/login',
        '/signup',
        '/forgot-password',
        '/reset-password',
        '/otp-validation',
        '/plans',
        '/payment-successful',
        '/payment-failed',
      ];

      if (publicPaths.includes(window.location.pathname)) {
        return newResult;
      }

      if (
        newResult?.meta?.response?.status === 401 ||
        newResult.error?.data?.code === 'INVALID_TOKEN' ||
        newResult.error?.data?.code === 'UNAUTHORIZED'
      ) {
        try {
          store.dispatch(logoutUser());
          update({
            name: '',
            customAttributes: {
              platform: 'student-web',
              deviceType: getDeviceType(),
            },
          });
          const cookies = Cookies.get();
          for (const cookie in cookies) {
            Cookies.remove(cookie);
          }

          console.log('logout');
          redirect('/login');
          return;
        } catch (error) {
          console.log('error', error);
          return;
        }

        // handleLogout();
        // await auth.signOut();

        // return
      } else {
        return newResult;
      }
    } else {
      return result;
    }
  } catch (error) {
    console.log('error', error);
    return error;
  }
}

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'MyProfile',
    'Search',
    'SingleProfile',
    'getVideos',
    'gradeInfo',
    'MyVideos',
    'CartItems',
    'WeeklyChallenge',
    'UserProfile',
    'AiChat',
  ],
  endpoints: (builder) => ({}),
});

export default apiSlice.reducer;
