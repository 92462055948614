import { apiSlice } from '..';

export const assignmentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAssignments: builder.query({
      query: (id) => {
        return {
          url: 'assignment/due-today/' + id,
          method: 'GET',
        };
      },
    }),
    getUserAssignments: builder.query({
      query: () => {
        return {
          url: '/assignment/webapp/list-user-assignments',
          method: 'GET',
        };
      },
    }),
    getSchoolAssignmentResult: builder.query({
      query: ({ id, userId }) => {
        return {
          url: `/assignment/school-assignment/result/${id}/${userId}`,
          method: 'GET',
        };
      },
    }),
    getTodayAssignments: builder.query({
      query: (id) => {
        return {
          url: 'assignment/due-today/' + id,
          method: 'GET',
        };
      },
    }),
    joinNonPinSchoolAssignment: builder.mutation({
      query: ({ id, userId }) => {
        return {
          url: `/assignment/school-assignment/join/no-pin/${id}/${userId}`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useLazyGetAssignmentsQuery,
  useGetAssignmentsQuery,
  useGetUserAssignmentsQuery,
  useLazyGetUserAssignmentsQuery,
  useGetSchoolAssignmentResultQuery,
  useLazyGetSchoolAssignmentResultQuery,
  useGetTodayAssignmentsQuery,
  useLazyGetTodayAssignmentsQuery,
  useJoinNonPinSchoolAssignmentMutation,
} = assignmentsApiSlice;
