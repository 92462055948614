import LoadingOverlay from 'app/components/LoadingOverlay';

import { Modal } from '@mantine/core';
import { auth } from 'api/firebase';
import useModal from 'components/Modal/hook/useModal';
import dayjs from 'dayjs';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useApproveOrRejectChildMutation,
  useGetDetailsByPaymentConfirmationIdQuery,
} from 'store/apis/payment';
import { logoutUser } from 'store/Slice/authSlice';
import styles from './styles.module.scss';
import cn from 'utils/cn';

const ChildApprovalSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(false);
  const { checkProfileAndNavigate } = useFirebaseLogin();
  const { openModal } = useModal();

  const isButtonEnabled = isCheckboxChecked && isSwitchEnabled;

  const [searchParams] = useSearchParams();

  const confirmationId = searchParams.get('confirmationId');
  const parentId = searchParams.get('parentId');

  const [approveOrReject, approveOrRejectResponse] =
    useApproveOrRejectChildMutation();

  const approveOrRejectChild = async (confirmationStatus) => {
    try {
      dispatch(logoutUser());
      await auth.signOut();
      const res = await approveOrReject({ confirmationId, confirmationStatus });
      if (confirmationStatus === 'CONFIRMED') {
        const phoneToken = res.data?.data?.verificationSid;
        const phone = res.data?.data?.parentConfirmation?.parent?.phone;
        const localParentId = res.data?.data?.parentConfirmation?.parent?.id;
        localStorage.setItem('childApproval', 'approved');
        console.log(res.data);

        if (!res?.data?.data?.isParentPhoneVerified) {
          navigate({
            pathname: '/otp-validation',
            search: `?confirmationId=${confirmationId}&parentId=${
              parentId || localParentId
            }&phoneToken=${phoneToken}&redirectTo=plans&phone=${phone}`,
          });
          return;
        }

        if (res?.data?.data?.isParentUserUpgradedToAPlan) {
          const parentUser = res.data.data.parentUser;
          const customToken = parentUser?.customToken;
          const user = parentUser?.user;

          if (customToken && user?.type === 'student') {
            await auth.signInWithCustomToken(customToken);
            const token = await auth.currentUser.getIdToken();
            localStorage.setItem('token', token);

            checkProfileAndNavigate(user, token, () => {
              navigate('/complete-profile');
            });
            return;
          }

          openModal('WarningModal', {
            title: 'Request Confirmed',
            description:
              "The request has been confirmed, Let's complete your profile.",
          });
          /* 
          if (!user?.dateOfBirth && user?.phone) {
            navigate('/birth?phoneNumber=' + user?.phone);
            return;
          }

          if (!user?.dateOfBirth && !user?.phone) {
            navigate('/birth');
            return;
          }

          if (user?.dateOfBirth && !user?.phone) {
            navigate(
              '/phone-validation?birthDate=' +
                dayjs(user?.dateOfBirth).format('YYYY-MM-DD'),
            );
            return;
          } */
          if (!user?.isPhoneVerified && user?.type !== 'student') {
            const query = queryString.stringify({
              phone: user?.phone,
              birthDate: user?.dateOfBirth
                ? dayjs(user?.dateOfBirth).format('YYYY-MM-DD')
                : undefined,
            });
            navigate('/phone-validation?' + query);
            return;
          }

          if (!customToken) {
            const query = queryString.stringify({
              phone: user?.phone,
              birthDate: user?.dateOfBirth
                ? dayjs(user?.dateOfBirth).format('YYYY-MM-DD')
                : undefined,
            });
            navigate('/over13?' + query);
            return;
          }

          if (customToken) {
            await auth.signInWithCustomToken(customToken);
            const token = await auth.currentUser.getIdToken();
            localStorage.setItem('token', token);

            checkProfileAndNavigate(user, token, () => {
              navigate('/complete-profile');
            });
            return;
          }

          return;
        }

        navigate({
          pathname: '/plans',
          search: `?confirmationId=${confirmationId}&parentId=${
            parentId || localParentId
          }`,
        });

        return;
      }

      if (confirmationStatus === 'REJECTED') {
        openModal('WarningModal', {
          title: 'Request Rejected',
          description: 'The request has been rejected.',
        });
        localStorage.clear();
        navigate('/');
        return;
      }
    } catch (error) {
      console.log(error);
      openModal('WarningModal', {
        title: 'Error',
        description:
          data?.message ||
          'Something went wrong, please try again later.',
      });
      console.log(error);
    }
  };

  const { data, isLoading } =
    useGetDetailsByPaymentConfirmationIdQuery(confirmationId,{
      skip: !confirmationId,
    });
  console.log(data);

  return (
    <Modal
      opened={true}
      onClose={() => {}}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      size={'xl'}
      centered
      classNames={{
        body: '!p-0 !shadow-lg inner-shadow',
      }}
      radius={'lg'}
    >
      <div className="px-4 py-8">
        <header className={styles.convinceToUpgradeHead}>
          <h3 className={styles.heading}>Child Account Approval Needed</h3>
        </header>
        <main className={styles.convinceToUpgradeBody}>
          <h3 className={styles.heading}>
            Before creating your account, please approve to access G3MS.
          </h3>
          <h3 className={styles.heading} style={{ textAlign: 'left' }}>
            Free Features Summary
          </h3>
          <p className={styles.bodyTxt} style={{ textAlign: 'left' }}>
            Here’s what {data?.data?.child?.name} {data?.data?.child?.lastname}{' '}
            can do with their free G3MS account:
          </p>
          <ul>
            <li>✅ Access state-aligned Math lessons.</li>
            <li>✅ Earn tokens to customize their avatar.</li>
            <li>✅ Use the camera for interactive challenges.</li>
            <li>
              ✅ Include a visual progress bar showing what features are
              unlocked for free.
            </li>
          </ul>
          <div>
            <div className="flex items-center gap-5">
              <input
                className="w-10 h-8 mx-2"
                type="checkbox"
                checked={isCheckboxChecked}
                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              />
              <h3 className={styles.bodyTxt}>
                I agree to allow my child to use the camera for challenges and
                interactive lessons. I have read and accept the{' '}
                <a className="hover:underline" href="#">
                  Terms & Conditions
                </a>
                .
              </h3>
            </div>
            <div className="flex items-center gap-5">
              <label className="relative inline-flex cursor-pointer items-center">
                <input
                  id="switch-2"
                  type="checkbox"
                  className="peer sr-only"
                  checked={isSwitchEnabled}
                  onChange={(e) => setIsSwitchEnabled(e.target.checked)}
                />
                <div className="peer h-4 w-11 rounded-full border bg-slate-200 after:absolute after:-top-1 after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#35A28B] peer-checked:after:translate-x-full peer-focus:ring-[#35A28B]"></div>
              </label>
              <div>
                <h2 className={styles.bodySmTxt} style={{ fontWeight: '600' }}>
                  Allow your kid videos to be shared
                </h2>
                <h3 className={styles.bodyTxt}>
                  I allow my child’s videos and images to be shared within the
                  G3MS community and external platforms through shared links.
                </h3>
              </div>
            </div>
          </div>
        </main>
        <footer className={styles.convinceToUpgradeFooter}>
          <button
            onClick={() => approveOrRejectChild('CONFIRMED')}
            // onClick={() => navigate(`/auth/login?type=parent`)}
            disabled={!isButtonEnabled}
            className={cn('order-1 lg:order-2', {
              [styles.activeButton]: isButtonEnabled,
              [styles.disabledButton]: !isButtonEnabled,
            })}
          >
            Approve & Continue
          </button>

          <button
            onClick={() => {
              approveOrRejectChild('REJECTED');
            }}
            className="order-2 lg:order-1"
          >
            Reject
          </button>
        </footer>
        <LoadingOverlay
          isLoading={approveOrRejectResponse.isLoading || isLoading }
        />
      </div>
    </Modal>
  );
};

export default ChildApprovalSettings;
