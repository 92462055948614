import { Drawer, Loader, LoadingOverlay } from '@mantine/core';
import { auth } from 'api/firebase';
import Button from 'components/Button';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useLazyGetMyProfileWithTokenQuery } from 'store/apis/profile';

const ClassLinkRedirect = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get('toke');
  const { checkProfileAndNavigate } = useFirebaseLogin();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [getMyProfileWithToken, getMyProfileWithTokenRes] =
    useLazyGetMyProfileWithTokenQuery();

  const [opened, setOpened] = useState(false);

  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [tokenData, setTokenData] = useState(null);

  useEffect(() => {
    (async () => {
      if (token) {
        try {
          setIsLoading(true);
          const res = await auth.signInWithCustomToken(token);
          const tken = await res.user.getIdToken();
          const userData = await getMyProfileWithToken(token).unwrap();
          if (isMobile) {
            setUser(userData?.data);
            setTokenData(tken);
            setOpened(true);
            setIsLoading(false);
            return;
          }
          checkProfileAndNavigate(userData?.data, tken);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setError(error);
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [token, isMobile]);

  console.log('isLoading', isLoading, 'error', error);

  return (
    <div className="h-dvh w-dvw p-5">
      <div className="h-full w-full grid place-content-center gap-4 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col items-center">
          {token && isLoading && (
            <div className="flex flex-col items-center gap-4">
              <Loader size="xl" />
              <p className="text-center font-semibold text-2xl">
                Redirecting...
              </p>
            </div>
          )}

          {!isLoading && error && (
            <div className="flex flex-col items-center gap-4">
              <p className="text-red-500 text-center font-semibold text-4xl">
                Something went wrong
              </p>
              <p className="text-center font-medium text-lg max-w-[80%]">
                {error?.message}
              </p>
              <Button onClick={() => navigate('/login')} className="w-1/2 mt-5">
                Go back to home
              </Button>
            </div>
          )}
        </div>
      </div>
      <Drawer
        offset={8}
        radius="md"
        opened={opened}
        title={
          <h1 className="text-2xl font-semibold text-center">
            Continue ClassLink
          </h1>
        }
        position="bottom"
        onClose={() => {}}
        size={'20%'}
        withCloseButton={false}
      >
        <div className="grid gap-4 w-full mt-4">
          <div className="flex gap-4 items-center justify-between">
            <div className="grid grid-cols-[auto_1fr] gap-4 items-center">
              <img
                src="/new-logo.png"
                alt="logo"
                className="w-12 h-12 rounded-full bg-white  object-fit-cover overflow-hidden"
              />
              <h2 className="text-lg font-semibold">Open in the App</h2>
            </div>
            <button
              className="border border-[#C73E9D]  bg-[#C73E9D] px-4 py-3 min-w-[10rem] rounded-full  text-white  font-medium"
              onClick={async () => {
                const currentUrl = window.location.origin;
                const appUrl = `${currentUrl}/mobile/token-login/${token}`;
                window.open(appUrl, '_self');
                setOpened(false);
              }}
            >
              Open
            </button>
          </div>
          <div className="flex gap-4 items-center justify-between">
            <div className="grid grid-cols-[auto_1fr] gap-4 items-center">
              <img
                src="/browser.png"
                alt="logo"
                className="w-12 h-12 rounded-full bg-white  object-fit-cover overflow-hidden"
              />
              <h2 className="text-lg font-semibold">Open in the Browser</h2>
            </div>
            <button
              className="border bg-white border-[#C73E9D] px-4 py-3 min-w-[10rem] rounded-full  text-[#C73E9D]  font-medium"
              onClick={async () => {
                try {
                  await checkProfileAndNavigate(user, tokenData);
                  setOpened(false);
                } catch (error) {
                  console.error(error);
                  setError(error);
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </Drawer>

      <LoadingOverlay
        visible={isLoading || getMyProfileWithTokenRes.isLoading}
        overlayBlur={2}
      />
    </div>
  );
};

export default ClassLinkRedirect;
