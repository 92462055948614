import { env } from '../config';

const prodConfig = {
  BASE_URL: 'https://live.g3ms.co/',
  WS: 'wss://live.g3ms.co/',
  INTERCOM_APP_ID: 'sd2zegi7',
  ADMIN_URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://live-admin-panel.g3ms.co',
};

const devConfig = {
  BASE_URL: 'https://dev.g3ms.co/',
  WS: 'wss://dev.g3ms.co/',
  INTERCOM_APP_ID: 'sd2zegi7',
  ADMIN_URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://dev-admin-panel.g3ms.co',
};

const config = env === 'dev' ? devConfig : prodConfig;

export default config;
