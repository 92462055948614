export function containsOnlyAllowedCharacters(input) {
  const regex = /^[a-zA-Z0-9 _-]*$/;
  return regex.test(input);
}


export function getDeviceType() {
  if (/android/i.test(navigator.userAgent)) return 'android';
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) return 'ios';
  return 'web';
}
