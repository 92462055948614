import { createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';
import {
  setDoneQuiz,
  setLeaderBoardList,
  setLeaderBoardTopTen,
  setQuizList,
  setQuizLoading,
} from 'store/Slice/quizSlice';
import API from 'store/api';

// export const getQuizList = createAsyncThunk(
//   'getQuizList',
//   async (data, { dispatch, rejectWithValue }) => {
//     const topic = data?.topic;
//     const skill = data?.skill;
//     let url = `/api/v1/assignment/webapp/templates/student/${data?.userId}`;

//     try {
//       if (topic) {
//         url += `?topic=${topic}`;
//       }
//       if (skill) {
//         url += `?skill=${skill}`;
//       }
//       const response = await API.get(url);
//       const templates = response.data.data;
//       console.log('=============getQuizList===============');
//       console.log(response.data);
//       dispatch(setQuizList(templates));
//       return response;
//     } catch (err) {
//       console.log('=============ERROR===============', err);
//       if (err?.code !== 'ERR_CANCELED') {
//         throw err;
//       }
//       return rejectWithValue(err.data);
//     }
//   },
// );

export const getQuizList = createAsyncThunk(
  'getQuizList',
  async (data, { dispatch, rejectWithValue }) => {
    console.log('getQuizList is review%%%%%%%%%%%%%%', data?.isReview);
    const topic = data?.topic;
    const skill = data?.skill;
    const isReview = data?.isReview || false;
    let url = `/api/v1/assignment/webapp/daily-practice-assignment-quiz/student/${
      data?.userId
    }?${queryString.stringify({
      isReviewQuestion: isReview ? 'true' : 'false',
    })}`;
    
    console.log('getQuizList is review URL%%%%%%%%%%%%%%', url);
    try {
      if (topic) {
        url += `?topic=${topic}`;
      }
      if (skill) {
        url += `?skill=${skill}`;
      }

      dispatch(setQuizLoading(true));
      const response = await API.get(url);
      const templates = response.data.data;
      console.log('=============getQuizList===============', templates);
      console.log(response.data);
      dispatch(setQuizList(templates));
      dispatch(setQuizLoading(false));
      return response;
    } catch (err) {
      dispatch(setQuizLoading(false));
      console.log('=============ERROR===============', err);
      if (err?.code !== 'ERR_CANCELED') {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  },
);

export const getLeaderList = createAsyncThunk(
  'getLeaderList',
  async (_request, { dispatch }) => {
    const topic = _request?.topic;
    let url = `api/v1/leaderboard/quiz?userId=${_request?.userId}`;
    // let url = `/api/v1/webapp/templates/student/c217a98c-7a83-46d3-8e6a-2b3726775ac1`;
    try {
      if (topic) {
        url += `&topic=${topic}`;
      }
      const response = await API.get(url);
      dispatch(setLeaderBoardList(response.data.data));
      return response;
    } catch (err) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);

export const updateDoneQuiz = createAsyncThunk(
  'updateDoneQuiz',
  async (data, { dispatch }) => {
    let url = `/api/v1/webapp/quiz-result/count-done-daily-quiz/${data?.userId}`;
    try {
      const response = await API.get(url);
      const count = response.data.data['count'];
      console.log('=============updateDoneQuiz===============');
      console.log(response.data);
      dispatch(setDoneQuiz(count >= 6));
      return response;
    } catch (err) {
      return err;
    }
  },
);

export const storeQuizResult = createAsyncThunk(
  'storeQuizResult',
  async (request, { dispatch }) => {
    let url = `/api/v1/webapp/quiz-result/${request.templateId}/`;
    try {
      if (request['2ndAttempt'] === true) {
        let _request = {
          studentId: request?.studentId,
          percentage2nd: Math.ceil(request.percentage2nd),
          assignmentId: request?.assignmentId,
          answerCorrect2nd: request.answerCorrect2nd,
        };
        await API.post(url, _request);
        return true;
      } else {
        let _request = {
          studentId: request?.studentId,
          percentage1st: Math.ceil(request.percentage1st),
          answerCorrect1st: request.answerCorrect1st,
          score: request.score,
          assignmentId: request?.assignmentId,
        };
        await API.post(url, _request);
        return true;
      }
    } catch (err) {
      return err;
    }
  },
);

export const submitQuestion = createAsyncThunk(
  'submitQuestion',
  async (request, { dispatch, rejectWithValue }) => {
    let url = `/api/v1/webapp/quiz-result/${request.studentId}/create-daily-practice-assignment-quiz-result`;
    try {
      let _request = {
        assignmentId: request.assignmentId,
        quizMode: 'WEB',
        question: request.question,
      };
      dispatch(setQuizLoading(true));

      const response = await API.post(url, _request);
      dispatch(setQuizLoading(false));

      return response.data;
    } catch (err) {
      dispatch(setQuizLoading(false));

      return rejectWithValue(err.data);
    }
  },
);
export const reviewQuestion = createAsyncThunk(
  'reviewQuestion',
  async (request, { rejectWithValue }) => {
    let url = `/api/v1/webapp/quiz-result/${request.templateId}/review-question/`;
    try {
      let _request = {
        studentId: request.studentId,
        assignmentId: request.assignmentId,
        questionId: request.questionId,
      };
      const response = await API.post(url, _request);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.data);
    }
  },
);

export const getLeaderBoardTopResult = createAsyncThunk(
  'getLeaderBoardTopResult',
  async (request, { dispatch }) => {
    try {
      let url = `/api/v1/webapp/leaderboard/90?gradeId=${request.gradeId}`;

      if (request?.topicId) {
        url += `&topicId=${request?.topicId}`;
      }
      if (request?.search) {
        url += `&queryName=${request?.search}`;
      }

      const response = await API.get(url);
      const leaderBoardResult = response.data.data;
      dispatch(setLeaderBoardTopTen(leaderBoardResult));
    } catch (err) {
      // dispatch(notificationFail(err.response.data.message));
      return err;
    }
  },
);
export const getStudentAssignments = createAsyncThunk(
  'getStudentAssignments',
  async ({ request }, { dispatch }) => {
    try {
      if (request?.classId) {
        const page = request?.pageIndex || 0;
        const size = request?.pageSize || 7;
        const search = request?.search || '';
        const studentId = localStorage.getItem('uuid');
        const queryParams = new URLSearchParams({
          page: String(page + 1),
          limit: String(size),
        });

        if (search.length > 0) {
          queryParams.append('query', search);
        }

        const url = `/api/v1/assignment/results/user/${studentId}/class/${
          request.classId
        }?${queryParams.toString()}`;

        const response = await API.get(url);
        const responseData = response?.data?.data;

        return responseData;
      }
    } catch (error) {
      return error;
    }
  },
);

export const getStudentAssignmentsInfo = createAsyncThunk(
  'storeQuizResult',
  async (request, { dispatch }) => {
    const studentId = localStorage.getItem('uuid');
    let url = `/api/v1/assignment/info/user/${studentId}`;
    try {
      const response = await API.get(url);
      return response?.data?.data;
    } catch (err) {
      return err;
    }
  },
);
