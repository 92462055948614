import { Box } from '@mui/material';
import useGetQuizTemplate from 'hooks/useGetQuizTemplate';
import PlayIcon from 'icons/PlayIcon';
import { MessageText1 } from 'iconsax-react';
import { Loading } from 'mdi-material-ui';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetMyProfileQuery } from 'store/apis/profile';
import FractionItem from './components/FractionItem';
import EarnArrow from './../../../../asset/dashboard/earn-g3ms-arrow.svg';
import GetWritingHelp from '../../../../asset/dashboard/get-writing-help.svg';

import AnimatedLogo from '../../../../asset/Bot-lottie1-CL-V1.json';
import Lottie from 'lottie-react';
import { features } from 'process';

function ShowAllContent({ videoTopic, setVideoTopic }) {
  const navigate = useNavigate();

  const { data, isLoading: myProfileLoading, error } = useGetMyProfileQuery();

  const { isLoading, navigateToQuiz, template } = useGetQuizTemplate();

  const challenges = React.useMemo(
    () => [
      {
        title: 'Quiz to Earn',
        description:
          'Share the G3MS app with your friends and earn rewards! 📲',
        icon: '/svg/methods/quiz-to-earn.svg',
        navigateTo: `/app/student/daily-quiz?quiz=${template.templateId}`,
        background: '#7979F726',
        borderClr: '#7979F7',
        btnTxt: 'Quiz Now',
      },
      {
        title: 'Code to Earn',
        description:
          'Show off your coding skills and earn G3MS tokens for every project completed! 💻',
        icon: '/svg/methods/code-to-earn.svg',
        navigateTo: '/app/student/challenge-selection',
        background: '#52A5D826',
        borderClr: '#52A5D8',
        btnTxt: 'Code Now',
      },
      {
        title: 'Share to Earn',
        description: 'Invite friends to G3MS and earn rewards when they join!',
        icon: '/svg/methods/quiz-to-earn.svg',
        navigateTo: '/app/student',
        background: '#AFAFAF26',
        borderClr: '#AFAFAF',
        btnTxt: 'Coming Soon',
        disabled: true,
      },
    ],
    [videoTopic, template],
  );

  const tutorFeatures = React.useMemo(
    () => [
      {
        title: 'Become a better Writer',
        description:
          'Need help structuring your paper? Our AI tutor guides you without writing it for you.',
        icon: require('../../../../asset/writing-h.png'),
        background: '#7979F726',
        btnTxt: 'Quiz Now',
      },
      {
        title: 'Ace Your Math Problems',
        description:
          'Stuck on a math problem? Get hints and learn step-by-step without just getting the answer.',
        icon: require('../../../../asset/math-h.png'),
        background: '#52A5D826',
        btnTxt: 'Get Help',
      },
      {
        title: 'Code Like a Pro',
        description:
          'Looking for coding help? Copy code snippets to use in our editor to build web apps and pages.',
        icon: require('../../../../asset/code-h.png'),
        background: '#AFAFAF26',
        btnTxt: 'Start Coding',
        disabled: true,
      },
    ],
    [],
  );

  return (
    <Box
      container
      sx={{
        marginLeft: '0px !important',
      }}
      className="!ml-0 "
      bgcolor={'#fff'}
      borderRadius={'30px'}
      alignItems={'start'}
    >
      <div className="grid">
        <div className="px-5 pt-5 w-full space-y-5">
          <h1 className="font-semibold text-base xl:text-lg 2xl:text-xl text-[#C73E9D]">
            <span className="text-[#20A58A]">Welcome</span>{' '}
            {data?.data?.name ?? data?.data?.username}!
          </h1>

          <main className="block  lg:grid lg:grid-cols-3 lg:gap-4">
            <section className="col-start-1 col-end-3">
              <div className="border border-[#00000033] rounded-2xl px-4 py-4 space-y-2 md:space-y-4 mb-4">
                <h1 className="font-semibold text-[#2C3E50] text-sm md:text-base lg:text-lg xl:text-xl">
                  Daily Practice
                </h1>

                <div className="block lg:grid lg:gap-4 space-y-4 md:space-y-4 lg:space-y-0">
                  <FractionItem
                    onClick={() => navigate('/app/student/video')}
                    image="/images/share-video.png"
                    title="Learn what matters from video lessons and earn G3MS."
                    btnTitle="Start Learning"
                  />

                  {/*    <FractionItem
                    onClick={() => navigateToQuiz(videoTopic)}
                    image="/images/start-quize.png"
                    title="Let’s start today’s daily practice quiz and earn G3MS."
                    btnTitle="Test your Knowledge"
                  /> */}
                </div>
              </div>
              <div className="border border-[#00000033] rounded-2xl px-4 py-4 space-y-2 md:space-y-4 mb-4">
                <h1 className="font-semibold text-[#2C3E50] text-sm md:text-base lg:text-lg xl:text-xl">
                  AI Tutor Features
                </h1>

                <div className="lg:grid gap-4 lg:grid-cols-3">
                  {tutorFeatures?.map((feature, _index) => (
                    <div className="w-full lg:flex lg:flex-col justify-between border border-[#E9EAEB] rounded-xl">
                      <div className="p-3 mb-2">
                        <div className="flex items-center gap-2 mb-3">
                          <img
                            className="w-10 h-10"
                            src={feature?.icon}
                            alt=""
                          />
                          <h1 className="font-semibold text-[#252843] text-sm xl:text-base 2xl:text-lg text-left mb-0">
                            {feature?.title}
                          </h1>
                        </div>
                        <p className="font-medium text-[#535862] text-xs xl:text-sm 2xl:text-base text-left">
                          {feature?.description}
                        </p>
                      </div>
                      {/* <div className='flex border-t border-[#C44297] p-3'>
                        <button className='bg-transparent border-none outline-none text-[#C44297] text-base font-semibold transition-all hover:underline ml-auto'>{feature?.btnTxt}</button>
                      </div> */}
                    </div>
                  ))}
                </div>
              </div>

              <div className="border border-[#00000033] rounded-2xl p-4 grid grid-cols-[1fr,auto] mb-4">
                <div className="space-y-4 flex flex-col justify-between items-start">
                  <h1 className="font-semibold text-[#2C3E50] text-sm md:text-base lg:text-lg xl:text-xl mb-7">
                    Need help with a topic? Let our AI Tutor guide you to the
                    solution without giving away the answer.
                  </h1>
                  <button
                    className="bg-[#C44297] text-white rounded-xl px-6 py-3 font-bold text-xs xl:text-sm flex items-center gap-2"
                    onClick={() => navigate('/app/student/chat')}
                  >
                    <MessageText1
                      className="text-white size-4 lg:size-5 "
                      variant="Bold"
                    />
                    <span>AI tutor</span>
                  </button>
                </div>
                <div className="">
                  <Lottie
                    className=" h-40 -mr-[42%]"
                    animationData={AnimatedLogo}
                  ></Lottie>
                </div>
              </div>
            </section>
            <section className="col-start-3 col-end-4">
              <div className="border border-[#00000033] rounded-2xl px-4 py-4 space-y-3 md:space-y-5">
                <h1 className="font-semibold text-[#2C3E50] text-sm md:text-base lg:text-lg xl:text-xl">
                  Earn G3MS
                </h1>
                <p className="text-[#2C3E50] font-medium text-xs md:text-sm xl:text-base">
                  Complete any challenge below and earn more $G3MS!
                </p>

                <div className=" lg:grid lg:gap-4 lg:grid-cols-1 mt-8">
                  {challenges.map((challenge, index) => (
                    <button
                      key={index}
                      className="w-full md:w-full lg:w-auto mt-4 lg:mt-0 px-5 py-6 md:py- lg:py-6 xl:py-6 border rounded-2xl grid place-items-center gap-2"
                      style={{
                        background: challenge?.background,
                        borderColor: challenge?.borderClr,
                      }}
                      onClick={() => navigate(challenge?.navigateTo)}
                    >
                      <div className="flex items-center gap-2">
                        <img
                          src={challenge?.icon}
                          alt=""
                          className="w-full h-full max-w-[2.5rem] 2xl:max-w-[2.75rem] object-contain"
                        />
                        <div>
                          <h1 className="font-semibold text-[#252843] text-sm xl:text-base 2xl:text-xl text-left">
                            {challenge?.title}
                          </h1>
                          <p className="font-medium text-[#252843] text-xs xl:text-sm 2xl:text-lg text-left">
                            {challenge?.description}
                          </p>
                        </div>
                      </div>
                      <button
                        className={`${
                          challenge?.disabled
                            ? 'bg-[#8080808C] pointer-events-none'
                            : 'bg-[#C44297]'
                        } text-white rounded-xl px-6 py-3 font-bold text-xs xl:text-sm flex items-center justify-self-end gap-2`}
                        onClick={() => navigate(challenge?.navigateTo)}
                      >
                        <img className="w-5" src={EarnArrow} alt="" />
                        <span>{challenge?.btnTxt}</span>
                      </button>
                    </button>
                  ))}
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
      <Loading open={isLoading || myProfileLoading} />
    </Box>
  );
}

export default ShowAllContent;
