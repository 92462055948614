// ** React Import
import { useEffect, useRef, useState } from 'react';
// ** MUI Import
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

// ** Third Party Components
import themeConfig from 'configs/themeConfig';

// ** Component Imports
import Drawer from './Drawer';
import VerticalNavHeader from './VerticalNavHeader';

// ** Util Import
import { hexToRGBA } from '@core/utils/hex-to-rgba';

import { useDispatch } from 'react-redux';
import { useGetMyProfileQuery } from 'store/apis/profile';
import { useAppSelector } from 'store/store';
import { getTeacherData } from 'store/Thunk/teacherThunk';
import cn from 'utils/cn';
import DailyPracticeAssignments from '../components/DailyPracticeAssignments';
import SidebarFooter from '../components/SidebarFooter';
import UserAssignmentInfo from '../components/UserAssignmentInfo';
import './style.css';
import SidebarMyData from '../components/SidebarMyData';

// ** Styled Components

const StyledBoxForShadow = styled(Box)(({ theme }) => ({
  top: 120,
  left: -8,
  zIndex: 2,
  display: 'none',
  position: 'absolute',
  pointerEvents: 'none',
  width: 'calc(100% + 15px)',
  height: theme.mixins.toolbar.minHeight,
  '&.d-block': {
    display: 'block',
  },
}));

const Navigation = (props) => {
  // ** Props
  const {
    hidden,
    settings,
    afterVerticalNavMenuContent,
    beforeVerticalNavMenuContent,
    verticalNavMenuContent: userVerticalNavMenuContent,
    toggleNavVisibility,
  } = props;

  console.log('props', props);

  // const { userData } = useAppSelector((state) => state.userReducer);
  const { user } = useAppSelector((state) => state.authReducer);

  const dispatch = useDispatch();
  const [userData, setUserData] = useState();

  const { data: userPersonalData } = useGetMyProfileQuery();
  useEffect(() => {
    if (user?.id) {
      dispatch(getTeacherData({ studentId: user?.id }));
    }
  }, [user?.id]);
  useEffect(() => {
    if (user?.id) {
      setUserData(userPersonalData?.data);
    }
  }, [userPersonalData]);

  // ** Ref
  const shadowRef = useRef(null);

  // ** Hooks
  const theme = useTheme();

  const { skin } = settings;

  const {
    afterVerticalNavMenuContentPosition,
    beforeVerticalNavMenuContentPosition,
  } = themeConfig;

  const shadowBgColor = () => {
    if (skin === 'semi-dark' && theme.palette.mode === 'light') {
      return `linear-gradient(${
        theme.palette.customColors.darkBg
      } 5%,${hexToRGBA(
        theme.palette.customColors.darkBg,
        0.85,
      )} 30%,${hexToRGBA(
        theme.palette.customColors.darkBg,
        0.5,
      )} 65%,${hexToRGBA(
        theme.palette.customColors.darkBg,
        0.3,
      )} 75%,transparent)`;
    } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
      return `linear-gradient(${
        theme.palette.customColors.lightBg
      } 5%,${hexToRGBA(
        theme.palette.customColors.lightBg,
        0.85,
      )} 30%,${hexToRGBA(
        theme.palette.customColors.lightBg,
        0.5,
      )} 65%,${hexToRGBA(
        theme.palette.customColors.lightBg,
        0.3,
      )} 75%,transparent)`;
    } else {
      return `linear-gradient(${
        theme.palette.background.default
      } 5%,${hexToRGBA(theme.palette.background.default, 0.85)} 30%,${hexToRGBA(
        theme.palette.background.default,
        0.5,
      )} 65%,${hexToRGBA(
        theme.palette.background.default,
        0.3,
      )} 75%,transparent)`;
    }
  };

  return (
    <Drawer {...props} className={cn(props.className, 'drawer')}>
      <VerticalNavHeader {...props} />
      {beforeVerticalNavMenuContent &&
      beforeVerticalNavMenuContentPosition === 'fixed'
        ? beforeVerticalNavMenuContent(props)
        : null}
      {(beforeVerticalNavMenuContentPosition === 'static' ||
        !beforeVerticalNavMenuContent) && (
        <StyledBoxForShadow
          ref={shadowRef}
          sx={{ background: shadowBgColor() }}
        />
      )}
      <Box
        sx={{
          position: 'relative',
          overflowY: 'auto',
          overflowX: 'hidden',
          height: 'calc(100dvh - 64px)',
        }}
        className="scrollbar-hidden"
      >
        {beforeVerticalNavMenuContent &&
        beforeVerticalNavMenuContentPosition === 'static'
          ? beforeVerticalNavMenuContent(props)
          : null}
        {userVerticalNavMenuContent ? (
          userVerticalNavMenuContent(props)
        ) : (
          <div className="flex flex-col gap-5 justify-between h-auto scrollbar-hidden">
            <div className="flex flex-col gap-5 px-4">
              <DailyPracticeAssignments
                toggleNavVisibility={toggleNavVisibility}
              />
              {/* <SidebarMyData /> */}
              <UserAssignmentInfo />
            </div>
            <SidebarFooter />
          </div>
        )}
        {afterVerticalNavMenuContent &&
        afterVerticalNavMenuContentPosition === 'static'
          ? afterVerticalNavMenuContent(props)
          : null}
      </Box>
      {afterVerticalNavMenuContent &&
      afterVerticalNavMenuContentPosition === 'fixed'
        ? afterVerticalNavMenuContent(props)
        : null}
    </Drawer>
  );
};

export default Navigation;

/* const NavBarItems = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const assignment = query.get('assignment');

  const [selected, setSelected] = useState('');
  const { schoolAssignmentTodayList, step } = useAppSelector(
    (state) => state.schoolAssignmentReducer,
  );
  const { user } = useAppSelector((state) => state.authReducer);

  const { doneQuiz } = useAppSelector((state) => state.quizReducer);

  console.log(schoolAssignmentTodayList, 'schoolAssignmentTodayList');

  const [navItems, setNavItems] = useState([
    {
      id: 1,
      title: 'Daily Practice',
      daily: true,
      isLive: false,
      done: false,
      isSelected: false,
    },
  ]);
  const [isAssignmentOpen, setIsAssignmentOpen] = useState(true);

  const { data } = useGetAssignmentsQuery(user?.id, {
    skip: !user?.id,
  });

  console.log('users data', data, user?.id);

  useEffect(() => {
    dispatch(
      updateDoneQuiz({
        userId: user?.id,
      }),
    );
  }, [dispatch, user?.id]);

  useEffect(() => {
    if (doneQuiz) {
      const dailyPracticeIdx = navItems.findIndex((item) => item.daily);
      const items = [...navItems];
      if (dailyPracticeIdx !== -1 && !items[dailyPracticeIdx].done) {
        items[dailyPracticeIdx].done = doneQuiz;
        setNavItems(items);
      }
    }
  }, [doneQuiz, navItems]);

  // useEffect(() => {
  //   dispatch(updateUserProfile());
  // }, [dispatch]);

  const [reloadList, setReloadList] = useState(0);
  useEffect(() => {
    if (user?.id) {
      dispatch(getDueTodaySchoolAssignments(user?.id));
    }
  }, [dispatch, user?.id, reloadList]);

  useEffect(() => {
    if (schoolAssignmentTodayList.length) {
      const items = [
        ...schoolAssignmentTodayList.map((item) => {
          return {
            id: item.id,
            // title: `${item.classroom.topic.name}: ${item.type}`,
            title: `${item.type}`,
            isLive:
              item.pinCode &&
              item.school_assignment_session.length > 0 &&
              !(item.school_assignment_user[0].status === 'Completed'),
            done: item.school_assignment_user[0].status === 'Completed',
            isSelected: false,
          };
        }),
      ];
      setNavItems((prev) => [...items, prev[prev.length - 1]]);
    }
  }, [schoolAssignmentTodayList]);

  useEffect(() => {
    if (assignment) {
      const idx = navItems.findIndex((item) => item.id === assignment);
      if (idx !== -1) {
        setSelected(idx);
      }
    }
  }, [assignment, navItems]);

  const navigate = useNavigate();

  const TodoTasks = () => {
    return navItems.map((item, index) => {
      console.log('items', navItems);

      const onSelectHandler = (item, index) => {
        if (selected === index) {
          return;
        }
        if (item.done) {
          setSelected(index);
          if (!item.daily) {
            const selectedAssignment = schoolAssignmentTodayList.find(
              (assignment) => assignment.id === item.id,
            );
            dispatch(setSelectedAssignment(selectedAssignment));
            navigate(`/app/student/?assignment=${item.id}`);
          } else {
            navigate(`/app/student`);
            dispatch(setSelectedAssignment({}));
          }
          return;
        } else {
          setSelected(index);
          item.daily
            ? navigate(`/app/student`)
            : navigate(`/app/student/?assignment=${item.id}`);
          if (!item.daily) {
            const selectedAssignment = schoolAssignmentTodayList.find(
              (assignment) => assignment.id === item.id,
            );
            dispatch(setSelectedAssignment(selectedAssignment));
            if (selectedAssignment.pinCode) {
              dispatch(setSchoolAssignmentStep(0));
              dispatch(setSchoolAssignmentStatus('Start'));
              dispatch(setJoinedSchoolAssignment({}));
            } else {
              dispatch(
                joinNonPinSchoolAssignment({
                  id: selectedAssignment.id,
                  userId: user?.id,
                }),
              )
                .unwrap()
                .then((response) => {
                  const timeDuration = selectedAssignment.timeDuration * 60;
                  dispatch(setTimerDurationSecs(timeDuration));
                  dispatch(setSchoolAssignmentStep(4));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            dispatch(setSelectedAssignment({}));
          }
        }
      };

      return (
        <>
          {!item.daily ? (
            <>
              <div
                key={item.id}
                onClick={() => onSelectHandler(item, index)}
                className="todo-item"
                style={{
                  display: 'flex',
                  backgroundColor:
                    index === selected ? '#ebf6f3' : 'transparent',
                  alignItems: 'center',
                  borderRadius: '7px',
                  width: '100%',
                  alignSelf: 'center',
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 15,
                  paddingLeft: 30,
                  justifyContent: 'space-between',
                  margin: 0,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '12px',
                    //opacity: item.done ? '40%' : '100%',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className=""
                    style={{
                      fontWeight: '400',
                      fontSize: '13px',
                      lineHeight: '22px',
                      color: '#131515',
                    }}
                  >
                    {item.title}
                  </div>
                </div>
                {item.isLive ? (
                  <div className="flex px-1 bg-[#F9F5FF] border border-[#E9D7FE] rounded-full items-center justify-between">
                    <div className="h-[5px] w-[5px] rounded-full bg-[#9E77ED] mr-1">
                      {' '}
                    </div>
                    <div className="text-[#6941C6] text-[9px] font-normal">
                      Live
                    </div>
                  </div>
                ) : item.done ? (
                  <div className="flex px-1  bg-[#ECFDF3] border border-[#ABEFC6] rounded-full items-center justify-between">
                    <div className="h-[5px] w-[5px] rounded-full bg-[#17B26A] mr-1">
                      {' '}
                    </div>
                    <div className="text-[#067647] text-[9px] font-normal">
                      Done
                    </div>
                  </div>
                ) : (
                  <div className="flex px-1 bg-[#FFFAEB] border border-[#FEDF89] rounded-full items-center justify-between">
                    <div className="h-[5px] w-[5px] rounded-full bg-[#F79009] mr-1">
                      {' '}
                    </div>
                    <div className="text-[#B54708] text-[9px] font-normal">
                      To Do
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : null}
        </>
      );
    });
  };
  const TodoDailyTasks = () => {
    return navItems.map((item, index) => {
      const onSelectHandler = (item, index) => {
        if (selected === index) {
          return;
        }
        if (item.done) {
          setSelected(index);
          if (!item.daily) {
            const selectedAssignment = schoolAssignmentTodayList.find(
              (assignment) => assignment.id === item.id,
            );
            dispatch(setSelectedAssignment(selectedAssignment));
            navigate(`/app/student/?assignment=${item.id}`);
          } else {
            navigate(`/app/student`);
            dispatch(setSelectedAssignment({}));
          }
          return;
        } else {
          setSelected(index);
          item.daily
            ? navigate(`/app/student`)
            : navigate(`/app/student/?assignment=${item.id}`);
          if (!item.daily) {
            const selectedAssignment = schoolAssignmentTodayList.find(
              (assignment) => assignment.id === item.id,
            );
            dispatch(setSelectedAssignment(selectedAssignment));
            if (selectedAssignment.pinCode) {
              dispatch(setSchoolAssignmentStep(0));
              dispatch(setSchoolAssignmentStatus('Start'));
              dispatch(setJoinedSchoolAssignment({}));
            } else {
              dispatch(
                joinNonPinSchoolAssignment({
                  id: selectedAssignment.id,
                  userId: user?.id,
                }),
              )
                .unwrap()
                .then((response) => {
                  const timeDuration = selectedAssignment.timeDuration * 60;
                  dispatch(setTimerDurationSecs(timeDuration));
                  dispatch(setSchoolAssignmentStep(4));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            dispatch(setSelectedAssignment({}));
          }
        }
      };

      return (
        <>
          {item.daily ? (
            <>
              <div
                key={item.id}
                onClick={() => onSelectHandler(item, index)}
                className="todo-item"
                style={{
                  display: 'flex',
                  backgroundColor:
                    index === selected ? '#ebf6f3' : 'transparent',
                  alignItems: 'center',
                  borderRadius: '12px',
                  width: '100%',
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingRight: 15,
                  paddingLeft: 15,
                  justifyContent: 'space-between',
                  margin: 0,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '12px',
                    //opacity: item.done ? '40%' : '100%',
                    alignItems: 'center',
                  }}
                >
                  <img className="cursor-pointer" src={BarChart} alt="" />

                  <div
                    className=""
                    style={{
                      fontWeight: '600',
                      fontSize: '14px',
                      lineHeight: '22px',
                      color: '#131515',
                    }}
                  >
                    {item.title}
                  </div>
                </div>
                {item.isLive ? (
                  <div className="flex px-1 bg-[#F9F5FF] border border-[#E9D7FE] rounded-full items-center justify-between">
                    <div className="h-[5px] w-[5px] rounded-full bg-[#9E77ED] mr-1">
                      {' '}
                    </div>
                    <div className="text-[#6941C6] text-[9px] font-normal">
                      Live
                    </div>
                  </div>
                ) : item.done ? (
                  <div className="flex px-1  bg-[#ECFDF3] border border-[#ABEFC6] rounded-full items-center justify-between">
                    <div className="h-[5px] w-[5px] rounded-full bg-[#17B26A] mr-1">
                      {' '}
                    </div>
                    <div className="text-[#067647] text-[9px] font-normal">
                      Done
                    </div>
                  </div>
                ) : (
                  <div className="flex px-1 bg-[#FFFAEB] border border-[#FEDF89] rounded-full items-center justify-between">
                    <div className="h-[5px] w-[5px] rounded-full bg-[#F79009] mr-1">
                      {' '}
                    </div>
                    <div className="text-[#B54708] text-[9px] font-normal">
                      To Do
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : null}
        </>
      );
    });
  };

  const handleWebSocketMessage = useCallback(
    (message) => {
      const { action, data } = message;
      if (action && data !== undefined) {
        const { assignment_id } = data;
        const { state } = data;
        if (assignment_id) {
          const index = navItems.findIndex((item) => item.id === assignment_id);
          if (index !== -1 && navItems[index].done === false) {
            switch (action) {
              case 'initiate':
                setNavItems((prev) => {
                  const items = [...prev];
                  items[index].isLive = true;
                  return items;
                });
                break;
                

              case 'start':
                if (step === 1 || step === 2) {
                  dispatch(setSchoolAssignmentStep(4));
                }
                if (state) {
                  dispatch(setJoinedSchoolAssignment(state));
                }
                break;

              case 'pause':
                dispatch(setSchoolAssignmentStep(1));
                dispatch(setSchoolAssignmentStatus('Resume'));
                break;

              case 'cancel':
                setNavItems((prev) => {
                  const items = [...prev];
                  items[index].isLive = false;
                  return items;
                });
                dispatch(setSchoolAssignmentStep(0));
                dispatch(setSchoolAssignmentStatus('Start'));
                dispatch(setJoinedSchoolAssignment({}));
                break;

              case 'timeout':
                setNavItems((prev) => {
                  const items = [...prev];
                  items[index].isLive = false;
                  return items;
                });
                break;

              default:
                break;
            }
          }
        }
        if (action === 'created') {
          setReloadList((prev) => prev + 1);
        }
      }
    },
    [step, navItems, dispatch],
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // gap: '10px',
      }}
    >
      {TodoDailyTasks()}
      <div
        style={{
          display: 'flex',
          backgroundColor: 'transparent',
          alignItems: 'center',
          borderRadius: '12px',
          width: '100%',
          paddingTop: 8,
          paddingBottom: 8,
          paddingRight: 15,
          paddingLeft: 15,
          justifyContent: 'space-between',
          margin: 0,
        }}
      >
        {' '}
        <div
          style={{
            display: 'flex',
            gap: '12px',
            //opacity: item.done ? '40%' : '100%',
            alignItems: 'center',
          }}
        >
          <img className="cursor-pointer" src={folder} alt="" />

          <div
            className=""
            style={{
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '22px',
              color: '#131515',
            }}
          >
            School Assignments
          </div>
          <div
            className="cursor-pointer ml-1"
            onClick={() => setIsAssignmentOpen(!isAssignmentOpen)}
          >
            {isAssignmentOpen ? (
              <KeyboardArrowUpIcon fontSize="small" />
            ) : (
              <ExpandMoreIcon fontSize="small" />
            )}
          </div>
        </div>
      </div>
      {isAssignmentOpen ? TodoTasks() : null}

      <WebSocketListener url={config.WS} onMessage={handleWebSocketMessage} />
    </div>
  );
}; */
