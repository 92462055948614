import WebSocketListener from '@core/components/Websocket';
import { Accordion, LoadingOverlay, Portal } from '@mantine/core';
import { nanoid } from '@reduxjs/toolkit';
import folder from 'asset/dashboard/folder.svg';
import config from 'configs/config';

import React, { useCallback } from 'react';
import { FaChevronUp } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setJoinedSchoolAssignment,
  setSchoolAssignmentStatus,
  setSchoolAssignmentStep,
  setSelectedAssignment,
  setTimerDurationSecs,
} from 'store/Slice/schoolAssignmentSlice';
import { joinNonPinSchoolAssignment } from 'store/Thunk/schoolAssignmentThunk';
import AssignmentAccordion from './AssignmentAccordion';
import { useJoinNonPinSchoolAssignmentMutation } from 'store/apis/assignments';
import { useAppSelector } from 'store/store';

const mapWebSocketActionToStatus = {
  created: 'Not_Started',
  initiate: 'Not_Started',
  start: 'Live',
  pause: 'Pause',
  cancel: 'Cancelled',
  resume: 'Resume',
};

function CRenderAssignments({
  title,
  total = 0,
  topics,
  type,
  toggleNavVisibility,
  onRefetch,
  navigateOnClickHeader,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.user);
  const [currentAssignment, setCurrentAssignment] = React.useState(null);

  const [joinNoPinAssignmentReq, joinNoPinAssignmentRes] =
    useJoinNonPinSchoolAssignmentMutation();
  const { step: stepValue } = useAppSelector(
    (state) => state.schoolAssignmentReducer,
  );

  const handleWebSocketMessage = useCallback(
    (message) => {
      const { action, data } = message;
      if (action && data !== undefined) {
        const { assignment_id } = data;

        // created,initiate,start,pause,cancel

        setCurrentAssignment({
          id: assignment_id,
          state: mapWebSocketActionToStatus[action],
        });

        switch (action) {
          case 'start':
            if (stepValue === 1 || stepValue === 2) {
              dispatch(setSchoolAssignmentStep(4));
            } else {
              dispatch(setSchoolAssignmentStep(0));
            }
            break;

          case 'pause':
            dispatch(setSchoolAssignmentStep(1));
            dispatch(setSchoolAssignmentStatus('Resume'));
            break;

          case 'cancel':
            dispatch(setSchoolAssignmentStep(0));
            dispatch(setSchoolAssignmentStatus('Start'));
            dispatch(setJoinedSchoolAssignment({}));
            break;

          case 'timeout':
            dispatch(setSchoolAssignmentStep(2));
            dispatch(setSchoolAssignmentStatus('Cancelled'));
            break;

          default:
            break;
        }

        console.log('WebSocket message received:', message);
      }
    },
    [stepValue],
  );

  const handleAssignmentClick = React.useCallback(
    async (assig) => {
      console.warn('assignment', assig);
      /*    if (assignment?.status === 'Completed' || assignment?.status === 'Done') {
      return;
    } */
      toggleNavVisibility?.();
      if (type === 'dailyPractice') {
        if (assig?.type === 'video') {
          dispatch(setSelectedAssignment({}));
          navigate(`/app/student/video?videoId=${assig.id}`);

          return;
        }
        navigate(
          `/app/student/daily-quiz?quiz=${assig?.id}&assignmentId=${assig?.assignmentId}`,
        );
      } else {
        assig = assig?.assignment || assig;

        dispatch(setSelectedAssignment(assig));

        const step = {
          start: 0,
          pause: 1,
          timeout: 2,
          completed: 3,
          quiz: 4,
        };

        const isPinCode = assig?.pinCode ? 'true' : 'false';

        console.log('dispatched assignment', assig);

        if (assig?.status === 'Started') {
          dispatch(setSchoolAssignmentStep(step.start));
          navigate(
            `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.start}`,
          );
          return;
        }

        if (assig?.status === 'Pause') {
          dispatch(setSchoolAssignmentStep(step.pause));
          navigate(
            `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.pause}`,
          );
          return;
        }

        if (assig?.status === 'Cancelled') {
          dispatch(setSchoolAssignmentStep(step.timeout));
          navigate(
            `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.timeout}`,
          );
          return;
        }

        if (assig?.status === 'Not_Started') {
          if (assig?.pinCode) {
            dispatch(setSchoolAssignmentStep(0));
            dispatch(setSchoolAssignmentStatus('Start'));
            dispatch(setJoinedSchoolAssignment({}));
            navigate(
              `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.start}`,
            );
            return;
          }
          try {
            await joinNoPinAssignmentReq({
              id: assig.id,
              userId: user?.id,
            }).unwrap();
            const timeDuration = (assig?.timeDuration || 1) * 60;
            dispatch(setTimerDurationSecs(timeDuration));
            dispatch(setSchoolAssignmentStep(4));
            navigate(
              `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.quiz}`,
            );
          } catch (error) {
            console.error('error?.data', error?.data);
            dispatch(setSchoolAssignmentStep(step.timeout));
            navigate(
              `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.timeout}`,
            );
          }
        }

        if (assig?.status === 'Resume' || assig?.status === 'Live') {
          if (assig?.pinCode) {
            dispatch(setSchoolAssignmentStep(0));
            dispatch(setSchoolAssignmentStatus('Start'));
            dispatch(setJoinedSchoolAssignment({}));

            navigate(
              `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.start}`,
            );
            return;
          }
          dispatch(setSchoolAssignmentStep(4));
          navigate(
            `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.quiz}`,
          );
          return;
        }

        if (assig?.status === 'Completed') {
          dispatch(setSchoolAssignmentStep(step.completed));
          navigate(
            `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.completed}`,
          );
          return;
        }

        if (assig?.status !== 'Completed') {
          if (assig.pinCode) {
            dispatch(setSchoolAssignmentStep(0));
            dispatch(setSchoolAssignmentStatus('Start'));

            dispatch(setJoinedSchoolAssignment({}));
          } else {
            try {
              await joinNoPinAssignmentReq({
                id: assig.id,
                userId: user?.id,
              }).unwrap();
              const timeDuration = (assig?.timeDuration || 1) * 60;
              dispatch(setTimerDurationSecs(timeDuration));
              dispatch(setSchoolAssignmentStep(4));
              navigate(
                `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.quiz}`,
              );
            } catch (error) {
              console.error('error?.data', error?.data);
              dispatch(setSchoolAssignmentStep(step.timeout));
              navigate(
                `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.timeout}`,
              );
            }
          }
        } else {
          dispatch(setSchoolAssignmentStep(step.completed));
          navigate(
            `/app/student?assignment=${assig.id}&isPinCode=${isPinCode}&status=${assig.status}&step=${step.completed}`,
          );
        }
      }
    },
    [type, user],
  );

  const accordions = React.useMemo(() => {
    const getInnerData = (assignment) => {
      const subItems = [];
      if (assignment?.videos) {
        const videos = assignment?.videos.map((video) => ({
          id: video?.video?.id,
          title: video?.videoContentName,
          type: 'video',
          videoInfo: video?.video,
          assignmentId: assignment?.id,
          pinCode: assignment?.pinCode,
          assignment,
          disabled: type === 'school' || video?.status === 'Completed',
          // status: video?.status,
        }));
        subItems.push(...videos);
      }
      if (assignment?.templates) {
        const templates = assignment?.templates.map((template) => ({
          id: template?.id || nanoid(5),
          title: template?.templateContentName,
          type: 'template',
          templateInfo: template?.template,
          assignmentId: assignment?.id,
          pinCode: assignment?.pinCode,
          assignment,
          disabled: type === 'school' || template?.status === 'Completed',
          // status: template?.status,
          description: template?.skill?.[0]?.skill?.name,
        }));
        subItems.push(...templates);
      }
      if (subItems.length) {
        return subItems;
      }
      return null;
    };

    if (!topics) return [];
    return topics?.map((topic) => {
      return {
        id: topic?.id,
        title: topic?.topic,
        count: topic?.count,
        subItems: topic?.assignments?.map((assignment) => {
          return {
            id: assignment?.id,
            title: assignment?.name,
            type,
            status:
              currentAssignment?.id === assignment?.id
                ? currentAssignment?.state
                : assignment?.status,
            subItems: getInnerData(assignment),
            data: assignment,
            pinCode: assignment?.pinCode,
          };
        }),
      };
    });
  }, [topics, type, currentAssignment?.id, currentAssignment?.state]);

  return (
    <>
      <div className="">
        <div className="grid grid-cols-[1fr,auto] rounded-md bg-[#20A58A]/10 p-2 gap-2">
          <h3 className="text-[#252B37] font-semibold text-[0.95rem]">
            {title}
          </h3>
          <div className="bg-[#C44297] ring ring-[#EDC4DF] grid place-items-center py-1 px-2 rounded-full w-9 h-6 text-white text-xs">
            <span>{total <= 99 ? total : '99+'}</span>
          </div>
        </div>

        {accordions.map((accordion) => {
          return (
            <AssignmentAccordion
              items={accordion.subItems}
              title={accordion.title}
              id={accordion.id}
              status={accordion.status}
              count={accordion.count}
              onItemClick={handleAssignmentClick}
              key={accordion.id}
              icon={<img src={folder} alt="folder" className="size-5" />}
              onHeaderClick={(assignment) => {
                if (type === 'school' && assignment) {
                  handleAssignmentClick(assignment);
                }
              }}
              disabled={type === 'school'}
            />
          );
        })}
      </div>
      <WebSocketListener url={config.WS} onMessage={handleWebSocketMessage} />
      <Portal>
        <LoadingOverlay visible={joinNoPinAssignmentRes.isLoading} />
      </Portal>
    </>
  );
}

const RenderAssignments = React.memo(CRenderAssignments);

export default RenderAssignments;
