import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import right from './right.mp3';

import MuxPlayer from '@mux/mux-player-react';
import { useQuery } from 'api/hooks';
import EmptyQuiz from 'app/student/components/emptyQuiz';
import { follower_profile } from 'asset';
import Failed2Dialog from 'dialog/failed2Popup';
import QuizWarning from 'dialog/quizWarning';
import ReviewSuccess from 'dialog/reviewSuccess';
import SuccessAnswer from 'dialog/successAnswer';
import WrongAnswer from 'dialog/wrongAnswer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Circles } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import {
  setHideTimer,
  setPauseTimer,
  setQuizLoading,
  setTimeGiven,
} from 'store/Slice/quizSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import AudioIcon from '../../../../../asset/person@2x.png';
import {
  getLeaderList,
  getQuizList,
  submitQuestion,
} from 'store/Thunk/quizThunk';
import { getUserData } from 'store/Thunk/userThunk';
import { getFontSize } from 'utils';
import { resultVideo } from 'utils/constants';
import { convertedOutput } from 'utils/processSentence';
import OptionsGrid from '../../AnswerOption/AnswerOption';
import './dailyQuiz.scss';
import QuizScore from './QuizScroe';
import ReviewDone from './ReviewDone';
import wrong from './wrong.mp3';
import MainHeader from '../../MainHeader';
import { useGetMyProfileQuery } from 'store/apis/profile';
import ProgressBarLinear from '../components/ProgressBarLinear';
import WrongAnswerReview from 'dialog/wrongAnswerReview';
import ReviewGetGems from 'dialog/ReviewGetGems';
import { assignmentsApiSlice } from 'store/apis/assignments';

function DailyQuiz({ state }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { leaderBoardList, quizLoading, quizList } = useAppSelector(
    (state) => state.quizReducer,
  );
  const { questionDuration } = useAppSelector((state) => state.quizReducer);

  const [template, setTemplate] = useState(null);
  const [userData, setUserData] = useState();

  const playerRef = useRef(null);
  const audioRef = useRef(null);
  const playSound = () => {
    audioRef?.current?.play();
  };
  const { user } = useAppSelector((state) => state.authReducer);
  // const { userData } = useAppSelector((state) => state.userReducer);
  const { data: userPersonalData, isLoading } = useGetMyProfileQuery();
  useEffect(() => {
    console.log('user$$$$$$$$**********', user?.id);
    setUserData(userPersonalData?.data);
  }, []);
  const query = useQuery();
  const topic = query.get('topic');
  const skill = query.get('skill');

  const passMark = useMemo(() => {
    return 80;
  }, []);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestionData, setCurrentQuestionData] = useState({});
  const [quizStat, setQuizStat] = useState({
    currentQuestion: {},
    currentQuestionIndex: 0,
    totalQuestionIndex: 0,
    correctAnswered: 0,
    incorrectAnswered: 0,
    totalQuestions: 0,
    quizCompleted: false,
    quizSummary: null,
  });

  useEffect(() => {
    setQuizStat((prevQuizStat) => ({
      ...prevQuizStat, // Spread the previous state to keep other data
      currentQuestion: currentQuestionData,
      currentQuestionIndex: template?.currentQuestion,
      totalQuestions: template?.totalQuestions,
    }));
  }, [currentQuestionData, template]);

  const [leaderBoard, setLeaderBoard] = useState([]);
  const [studentReply, setStudentReply] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showNext, setShowNext] = useState(false);
  const timeRef = useRef(60);

  const timer = timeRef.current;
  const [checkExpired, setCheckExpired] = useState(false);

  const setTimer = (time) => {
    timeRef.current = time;
  };

  // const [timer, setTimer] = useState(60); // 60 seconds timer
  const [showError, setShowError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showReviewSuccess, setShowReviewSuccess] = useState(true);
  const [showWrong, setShowWrong] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [totalGems, setTotalGems] = useState(0);
  const [finalMarks, setFinalMarks] = useState({ marks: 0, complete: false });
  const [answeredCount, setAnsweredCount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [quizObj, setQuizObj] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);
  const [failedPopup, setFailedPopup] = useState(false);
  const [failed2Popup, setFailed2Popup] = useState(false);
  const [secondTrial, setSecondTrial] = useState(false);
  const [videoTopic, setVideoTopic] = useState('');
  const [quizSkill, setquizSkill] = useState(null);
  const [perQuestion, setPerQuestion] = useState(0);
  const [suffledVideo, setSuffledVideo] = useState({
    do_not_stop: '',
    do_it_again: '',
  });

  const resetPage = () => {
    //except video and suffledVideo
    setSecondTrial(true);
    setCurrentQuestionIndex(0);
    setCurrentQuestionData({});
    setLeaderBoard([]);
    setStudentReply([]);
    setSelectedAnswer('');
    setShowNext(false);
    setTimer(60);
    setShowError(false);
    setShowPopup(false);
    setShowSuccess(false);
    setShowWrong(false);
    setTotalMarks(0);
    setAnsweredCount(0);
    setFinalMarks({ marks: 0, complete: false });
    setTotalTime(0);
    setQuizObj({});
    setSuccessPopup(false);
    setFailedPopup(false);
    setFailed2Popup(false);
    dispatch(setQuizLoading(true));
    dispatch(
      getQuizList({
        // topic: videoTopic,
        userId: user?.id,
        // skill: quizSkill,
        isReview: isReview,
        // gradeId: userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(setQuizLoading(false));
        dispatch(getUserData({ studentId: user?.id }));
        if (quiz) {
          navigate('/app/student/daily-quiz', { state: videoTopic });
        }
      })
      .catch((err) => {
        console.log('error', err);
        dispatch(setQuizLoading(false));
      })
      .finally(() => {
        dispatch(setQuizLoading(false));
      });
  };
  useEffect(() => {
    if (topic) {
      setVideoTopic(topic);
    } else {
      setVideoTopic('');
    }
  }, [topic]);
  useEffect(() => {
    if (skill) {
      setquizSkill(skill);
    }
  }, [skill]);
  useEffect(() => {
    if (userData?.id) {
      if (quizList.length > 0) {
        console.log(
          'QUIZ LIST EXISTS',
          quizList.length,
          quizList.map((item) => item.id),
        );
        return;
      }

      console.log('::RAN THIS AGAIN<============', isReview);
      dispatch(setQuizLoading(true));
      dispatch(
        getQuizList({
          // topic: videoTopic,
          // skill: quizSkill,
          isReview: isReview,
          userId: userData?.id,
          // gradeId:
          //   userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        }),
      ).then((res) => {
        if (res.type === 'getQuizList/fulfilled') {
          dispatch(setQuizLoading(false));
        }
      });
    }
    // }, [videoTopic, userData?.grade, quizSkill, quizList, userData?.id]);
  }, [userData?.id]);

  const quiz = query.get('quiz');

  useEffect(() => {
    if (quizList) {
      setTemplate(quizList);
    }
  }, [quizList]);

  useEffect(() => {
    if (leaderBoardList.length) {
      setLeaderBoard(leaderBoardList);
    }
  }, [leaderBoardList]);

  const replaceRaceNumber = (text) => {
    const regex = /\^(\d+)/;
    const convertedText = reactStringReplace(text, regex, (match, i) => (
      <sup key={i}>{match}</sup>
    ));

    return convertedText;
  };

  useEffect(() => {
    if (template) {
      console.log('=========We have a template=======', template);
      setQuizObj(template);
      if (template?.id) {
        dispatch(getLeaderList({ userId: user?.id, topic: videoTopic }));
      }
      if (template?.quizQuestion?.question) {
        setCurrentQuestionData(template?.quizQuestion?.question || {});
      }
    }
  }, [template]);

  const secondAttempt = template?.order === 2;

  // Set actual template object
  let templateObj = null;
  template?.templates?.forEach((temp) => {
    if (temp.template.id === template?.questions[0]?.templateId) {
      templateObj = temp.template;
    }
  });

  useEffect(() => {
    setSuffledVideo({
      do_not_stop: resultVideo?.do_not_stop?.sort(() => Math.random() - 0.5)[0],
      do_it_again: resultVideo?.do_it_again?.sort(() => Math.random() - 0.5)[0],
      be_right_back: resultVideo?.be_right_back?.sort(
        () => Math.random() - 0.5,
      )[0],
    });
  }, []);

  useEffect(() => {
    if (template?.quizQuestion?.question) {
      setCurrentQuestionData(template?.quizQuestion?.question || {});
    }
  }, [selectedAnswer]);

  const handleAnswerSelect = (optionKey) => {
    setSelectedAnswer((prevAnswer) =>
      prevAnswer === optionKey ? '' : optionKey,
    );
    setShowNext(false);
    setShowError(false);
  };

  useEffect(() => {
    if (showError) {
      setTimeout(() => {
        setShowError(false);
      }, 1000);
      return;
    }
  }, [showError]);

  const getG3MSPercentage = () => {
    let percentage = 0;
    if (user && Object.keys(user).length) {
      const g3msScore = totalGems + (user?.gemsTokens || 0);
      percentage = (g3msScore * 100) / user?.totalGems;
    }
    return isNaN(percentage) ? 0 : percentage;
  };

  const [isSpeaking, setIsSpeaking] = useState(false);

  const getPercentage = useCallback(() => {
    let correctAnswers = 0;
    studentReply.forEach((reply) => {
      if (reply.student_answer === reply.correct_answer) {
        correctAnswers += 1;
      }
    });
    return (correctAnswers * 100) / quizObj?.questions?.length;
  }, [studentReply, quizObj]);
  const quizResult = {
    IsReviewQuestionsAvailablePerQuiz: false,
    TotalNumberOfCorrectlyAnsweredPerQuiz: 3,
    TotalNumberOfIncorrectlyAnsweredPerQuiz: 2,
    TotalQuizDurationTaken: 266,
    TotalScoreForTheQuiz: 60,
    TotalTokensEarnedPerQuiz: 17,
  };
  const percentage = getPercentage();
  const handleNextQuestion = useCallback(() => {
    dispatch(setPauseTimer(true));
    if (!template) {
      return;
    }
    const assignmentId = template?.assignmentId;

    setIsSpeaking(false);
    if (!selectedAnswer) {
      setShowError(true);
      return;
    }
    let replyObj = {
      questionId: currentQuestionData.id,
      student_answer: selectedAnswer.toLocaleLowerCase(),
      correct_answer: currentQuestionData.answer.toLocaleLowerCase(),
    };
    let replyArr = [...studentReply];
    replyArr.push(replyObj);
    setStudentReply(replyArr);
    setShowNext(true);

    dispatch(
      submitQuestion({
        assignmentId: assignmentId,
        quizMode: 'WEB',
        assignmentType: 'DailyPractice',
        question: {
          questionId: currentQuestionData.id,
          templateId: template?.templateId,
          studentAnswer: selectedAnswer.toLowerCase(),
          isReviewed: isReview,
          durationTaken: questionDuration,
        },
        studentId: user?.id,
      }),
    )
      .unwrap()
      .then((res) => {
        dispatch(assignmentsApiSlice.util.resetApiState());
        console.log('response after submit', res);
        setTotalGems((prevGems) => prevGems + res?.data?.newGemTokens || 0);
        setShowNext(true);
        if (template?.totalQuestions === template?.currentQuestion) {
          setQuizStat((prevQuizStat) => ({
            ...prevQuizStat, // Spread the previous state to keep other data
            quizCompleted: true,
          }));
        }
        // !res.data?.isQuizFinished &&

        // if (!res.data?.isQuizFinished || isReview) {
        //   dispatch(
        //     getQuizList({
        //       topic: videoTopic,
        //       skill: quizSkill,
        //       isReview: isReview,
        //       userId: userData?.id,
        //       gradeId:
        //         userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        //     }),
        //   );
        // }
        if (res.data?.isQuizFinished) {
          setQuizStat((prevQuizStat) => ({
            ...prevQuizStat, // Spread the previous state to keep other data
            quizSummary: res.data?.quizResultSummary,
          }));
          dispatch(setHideTimer(true));
        }
        if (res.data?.isAnswerCorrect) {
          setPerQuestion(() => 10);
          setTotalMarks((prevTotalMarks) => prevTotalMarks + 10);
          setAnsweredCount((prevTotalMarks) => prevTotalMarks + 1);
          setQuizStat((prevQuizStat) => ({
            ...prevQuizStat, // Spread the previous state to keep other data
            correctAnswered: prevQuizStat?.correctAnswered + 1,
          }));
        }
        if (!res.data?.isAnswerCorrect) {
          setPerQuestion(() => 0);
          setQuizStat((prevQuizStat) => ({
            ...prevQuizStat, // Spread the previous state to keep other data
            incorrectAnswered: prevQuizStat?.incorrectAnswered + 1,
          }));
        }
        selectedAnswer &&
          setShowSuccess(
            selectedAnswer.toLocaleLowerCase() ===
              currentQuestionData.answer.toLocaleLowerCase(),
          );
        selectedAnswer &&
          setShowWrong(
            selectedAnswer.toLocaleLowerCase() !==
              currentQuestionData.answer.toLocaleLowerCase(),
          );
      });
  }, [
    selectedAnswer,
    template,
    user?.id,
    currentQuestionData,
    studentReply,
    dispatch,
  ]);

  // const handleReviewQuestion = useCallback(() => {
  //   if (!template) {
  //     return;
  //   }
  //   const assignmentId = template?.id;

  //   const isAnswerCorrect =
  //     selectedAnswer.toLowerCase() === currentQuestionData.answer.toLowerCase();

  //   if (isAnswerCorrect) {
  //     setPerQuestion(() => 10);
  //     setTotalMarks((prevTotalMarks) => prevTotalMarks + 10);
  //     setAnsweredCount((prevTotalMarks) => prevTotalMarks + 1);
  //   }
  //   if (!isAnswerCorrect) {
  //     setPerQuestion(() => 0);
  //   }
  //   dispatch(
  //     reviewQuestion({
  //       templateId: currentQuestionData?.templateId,
  //       studentId: user?.id,
  //       assignmentId: assignmentId,
  //       questionId: currentQuestionData.id,
  //     }),
  //   )
  //     .unwrap()
  //     .then((res) => {
  //       setTotalGems((prevGems) => prevGems + res?.data?.newGemTokens || 0);
  //       setShowReviewSuccess(true);
  //       setShowNext(true);
  //     });
  // }, [selectedAnswer, template, user?.id, currentQuestionData, dispatch]);

  useEffect(() => {
    (showSuccess || showWrong) && playSound();
  }, [showSuccess, showWrong]);

  useEffect(() => {
    let interval;
    const fiveMinutes = 0;

    if (!checkExpired) {
      interval = setInterval(() => {
        const updatedTime = timeRef.current - 1;

        setTimer(updatedTime);

        if (updatedTime === fiveMinutes) {
          setCheckExpired(true);
          clearInterval(interval);
        }
      }, 1000);
    } else {
      if (!selectedAnswer && !finalMarks?.complete) {
        setShowError(true);

        const popupTimeout = setTimeout(() => {
          setShowPopup(true);
        }, 60 * 1000);

        return () => clearTimeout(popupTimeout);
      }
    }

    return () => clearInterval(interval);
  }, [timer, selectedAnswer, currentQuestionData.correctAnswer, checkExpired]);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handlePopupResponse = (response) => {
    setShowPopup(false);
    if (response === 'yes') {
      setCurrentQuestionIndex((prevQuestion) => prevQuestion + 1);
      setSelectedAnswer('');
      setTimer(60);
    } else {
      console.log('User and session flagged!');
    }
  };
  console.log('showReviewSuccess', showReviewSuccess);
  console.log('showReviewSuccess', secondTrial);
  const handleSuccessReviewDialog = () => {
    setShowReviewSuccess(false);
  };
  const handleSuccessDialog = () => {
    setShowSuccess(true);
  };

  const onCloseReviewSuccess = () => {
    setShowReviewSuccess(false);
  };
  const onCloseSuccess = () => {
    dispatch(setPauseTimer(false));
    dispatch(setTimeGiven(60));
    if (template.currentQuestion < 5 || isReview) {
      dispatch(
        getQuizList({
          // topic: videoTopic,
          // skill: quizSkill,
          isReview: isReview,
          userId: userData?.id,
          // gradeId:
          //   userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        }),
      );
    }
    setPerQuestion(() => 0);
    setShowSuccess(false);
    setSelectedAnswer('');
    setTimer(60);
  };
  const handleWrongDialog = () => {
    setShowWrong(true);
  };
  const [showExplaination, setShowExplaination] = useState(false);
  const onCloseWrong = (type) => {
    setGetGemsOnReview(false);
    setShowExplaination(false);
    dispatch(setPauseTimer(false));
    dispatch(setTimeGiven(60));
    setShowWrong(false);
    setSelectedAnswer('');
    setTimer(60);
    if (template.currentQuestion < 5) {
      dispatch(
        getQuizList({
          // topic: videoTopic,
          // skill: quizSkill,
          isReview: isReview,
          userId: userData?.id,
          // gradeId:
          //   userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
        }),
      )
        .then((res) => {
          console.log('res', res);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };
  const onCloseReviewWrong = () => {
    dispatch(setPauseTimer(false));
    dispatch(setTimeGiven(60));
    setShowWrong(false);
    setSelectedAnswer('');
    setTimer(60);
    setShowExplaination(true);
  };
  const handleOnEnded = () => {
    if (percentage >= passMark) {
      // navigate('/app/student')
      setSuccessPopup(true);
    }
    if (percentage < passMark && !secondAttempt) {
      setFailedPopup(true);
    }
    if (percentage < passMark && secondAttempt) {
      setFailed2Popup(true);
    }
  };
  const [isReview, setIsReview] = useState(false);
  const handleReview = () => {
    setIsReview(true);
    dispatch(
      getQuizList({
        // topic: videoTopic,
        // skill: quizSkill,
        isReview: true,
        userId: userData?.id,
        // gradeId: userData?.grade?.length > 0 ? userData?.grade[0]?.gradeId : '',
      }),
    );
  };
  const [getGemsOnReview, setGetGemsOnReview] = useState();
  const handleSuccessPopup = () => {
    resetPage();
    navigate('/app/student');
  };
  const handleOnCloseFailed = () => {
    navigate('/app/student');
  };

  const handleVideo = () => {
    if (percentage >= passMark || isReview || quizStat.correctAnswered === 5) {
      return suffledVideo?.do_not_stop;
    }
    if (
      (percentage < passMark &&
        template &&
        Object.keys(template).length &&
        !secondAttempt) ||
      quizStat.correctAnswered < 5
    ) {
      return suffledVideo?.do_it_again;
    }
    if (
      percentage < passMark &&
      template &&
      Object.keys(template).length &&
      secondAttempt
    ) {
      return suffledVideo?.be_right_back;
    }
  };

  const handleSpeakClick = () => {
    setIsSpeaking(true);
  };
  const [speechSynthesisObj, setSpeechSynthesisObj] = useState(null);

  useEffect(() => {
    // const message = new SpeechSynthesisUtterance(
    //   convertedOutput(
    //     `${currentQuestionData?.question}
    //      A,
    //      ${currentQuestionData?.optionA}" ", B ,
    //      ${currentQuestionData?.optionB}" ", C ,
    //      ${currentQuestionData?.optionC}" ", D , ${currentQuestionData?.optionD}`,
    //   ),
    // );
    const question = currentQuestionData?.question;
    const optionA = currentQuestionData?.optionA;
    const optionB = currentQuestionData?.optionB;
    const optionC = currentQuestionData?.optionC;
    const optionD = currentQuestionData?.optionD;

    const options = [
      { label: 'A', value: optionA },
      { label: 'B', value: optionB },
      { label: 'C', value: optionC },
      { label: 'D', value: optionD },
    ];

    const nonEmptyOptions = options.filter(
      (option) =>
        option.value !== null &&
        option.value !== undefined &&
        option.value !== '',
    );

    const messageContent = nonEmptyOptions
      .map((option) => {
        return `, ${option.label}, ${option.value}`;
      })
      .join('');

    const message = new SpeechSynthesisUtterance(
      `${question}${messageContent}`.replaceAll('\\', ''),
    );

    // Set isSpeaking to false when done speaking
    message.onend = () => {
      setIsSpeaking(false);
    };

    const synthesis = window.speechSynthesis;

    setSpeechSynthesisObj(message);

    if (isSpeaking) {
      synthesis.speak(message);
    }
    return () => {
      synthesis.cancel(); // Make sure to cancel the speech when the component unmounts
    };
  }, [isSpeaking, currentQuestionData]);
  useEffect(() => {}, []);
  // const handleStopSpeech = () => {
  //   if (speechSynthesisObj) {
  //     window.speechSynthesis.cancel();
  //   }
  // };

  function removeSequenceNumber(text) {
    const arrs = text.split(':');
    return arrs[arrs.length - 1];
  }
  console.log(
    'final',
    (quizStat?.quizCompleted && !isReview) || (isReview && !template.message),
  );
  console.log('final', quizStat?.quizCompleted, isReview, template?.message);

  return (
    <div className="h-[100%] m-0">
      {/* Display the total time taken */}
      {/* <Typography variant="h6">Total Time: {totalTime} seconds</Typography> */}
      {quizLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Circles
            height="80"
            width="80"
            color="#20A58A"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      <MainHeader onClick={() => navigate('/app/student')} text={'Quiz'} />
      <div className="lg:h-[92dvh] md:h-[97dvh] p-4">
        {/* {quizObj && Object.keys(quizObj).length > 0 ? ( */}

        {(template && !template?.message) || quizStat?.quizSummary ? (
          <>
            <div className="lg:flex h-[100%] block " container>
              <div
                //Main Div of both+++++++++++++
                style={{
                  gap: '20px',
                  height: '100%',
                  flex: 2,
                  // border: '6px solid rgba(76, 191, 167, 1)',
                  borderRadius: '20px',
                  background: '#FFFFFF',
                }}
                container
              >
                {(!quizStat?.quizCompleted && !template?.message) ||
                (isReview && !template.message) ? (
                  <CardContent
                    sx={{
                      display: 'flex',
                      flex: '1',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ m: 2, marginBottom: '2rem' }}>
                      {currentQuestionData?.question ? (
                        <div>
                          <div className="flex items-center justify-between">
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: '13px',
                                color: '#000000',
                              }}
                            >
                              {template?.currentQuestion}:
                              {template?.totalQuestions}
                            </Typography>
                            {isReview ? null : (
                              <div className="w-full mx-4">
                                <ProgressBarLinear
                                  bgcolor={'#0066FF'}
                                  progress={
                                    (Math.round(
                                      (100 * template?.currentQuestion) | 1,
                                    ) /
                                      template?.totalQuestions) |
                                    1
                                  }
                                  height={9}
                                />
                              </div>
                            )}

                            <div role="button" onClick={handleSpeakClick}>
                              <img
                                src={AudioIcon}
                                alt="speaker"
                                style={{
                                  width: '20px',
                                  height: '20px',
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Skeleton
                          variant="rounded"
                          width={220}
                          height={30}
                          sx={{ mb: 3 }}
                        />
                      )}
                      {showWrong && <audio ref={audioRef} src={wrong} />}
                      {showSuccess && <audio ref={audioRef} src={right} />}
                    </Box>
                    {showError && (
                      <Alert
                        severity="error"
                        onClose={() => setShowError(false)}
                      >
                        Select Answer!
                      </Alert>
                    )}
                    {Object.entries(currentQuestionData)?.length ? (
                      <div
                        className="h-[78vh]"
                        style={{
                          // paddingLeft: 10,

                          // background: template?.colorCode
                          //   ? `${
                          //       template?.colorCode.startsWith('#') ? '' : '#'
                          //     }${template?.colorCode}`
                          //   : '#FFFFFF',
                          background: '#FFF4EF',
                          borderRadius: ['10px'],
                          // height: '100vh',
                          paddingTop: 15,
                        }}
                      >
                        <div className="w-[98%] pb-36 border-b border-[#c8c4c3] px-3">
                          <Typography
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                            }}
                          >
                            {removeSequenceNumber(
                              template?.templateTitle || '',
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              overflowY: 'scroll',
                              fontWeight: 500,
                              fontSize: '18px',
                              color: '#000000',
                            }}
                          >
                            {currentQuestionData?.message}
                          </Typography>
                          <Typography
                            sx={{
                              // fontSize: `${getFontSize(
                              //   currentQuestionData?.question,
                              // )}px`,
                              fontSize: '18px',
                              fontWeight: 500,
                              p: 1,
                            }}
                            variant="subtitle2"
                          >
                            {currentQuestionData?.question &&
                              typeof currentQuestionData?.question ===
                                'string' && (
                                <Latex>{currentQuestionData?.question}</Latex>
                              )}
                          </Typography>
                          {currentQuestionData?.questionMedia && (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',

                                height: 'auto',
                                width: '100%',
                              }}
                            >
                              {currentQuestionData?.type === 'image' ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: 'auto',
                                    width: '100%',
                                  }}
                                >
                                  <img
                                    src={currentQuestionData?.questionMedia}
                                    alt={`question-media`}
                                    style={{
                                      maxWidth: '100%',
                                      maxHeight: '65vh',
                                      width: '100%',
                                      // margin: '10px',
                                      objectFit: 'contain',
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <video
                                    controls
                                    width="500px"
                                    height="400px"
                                    // style={{ aspectRatio: 16 / 9, height: '200px' }}
                                  >
                                    <source
                                      src={currentQuestionData?.questionMedia}
                                      type={`video/${currentQuestionData?.questionMedia
                                        .split('.')
                                        .pop()
                                        .toLowerCase()}`}
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {showExplaination ? (
                          <div className="p-4">
                            <div className="font-bold text-[20px] font-[#000000]">
                              Explanation:
                            </div>
                            <div className="font-normal text-[16px] font-[#000000] mt-3">
                              <Latex>{currentQuestionData?.explanation}</Latex>
                            </div>
                            <div className="w-full flex items-center justify-center lg:mt-56 mt-20 ">
                              <div className="lg:w-[20%] w-[100%]">
                                <Button
                                  sx={{
                                    textTransform: 'none',
                                    background: '#C73E9D',
                                    color: '#FFFFFF',

                                    '&.MuiButtonBase-root:hover': {
                                      backgroundColor: '#C73E9D',
                                    },
                                  }}
                                  fullWidth
                                  onClick={() => setGetGemsOnReview(true)}
                                >
                                  {'Next'}
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center justify-center bg-black">
                            <div className=" w-[25rem] md:w-[55rem] lg:w-[95%] lg:mb-3 mb-12 bottom-16 lg:bottom-12 md:bottom-27  fixed">
                              <OptionsGrid
                                selectedAnswer={selectedAnswer}
                                handleAnswerSelect={handleAnswerSelect}
                                currentQuestionData={currentQuestionData}
                                convertedOutput={convertedOutput}
                              />

                              <div className="w-full flex items-center justify-center mt-3">
                                <div className="lg:w-[20%] w-[100%]">
                                  <Button
                                    sx={{
                                      textTransform: 'none',
                                      background: '#C73E9D',
                                      color: '#FFFFFF',
                                      '&.MuiButtonBase-root:hover': {
                                        backgroundColor: '#C73E9D',
                                      },
                                    }}
                                    fullWidth
                                    onClick={handleNextQuestion}
                                  >
                                    {isReview ? 'Submit' : 'Next'}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <Skeleton variant="rounded" width={380} height={130} />
                    )}
                  </CardContent>
                ) : (
                  // <CardContent>
                  <>
                    {/* <Grid item xs={6} md={6} display={'flex'} justifyContent={'center'} alignItems={'center'}> */}
                    <div className="lg:flex  h-[100%] w-[100%] rounded-md bg-[#F8F8F8] items-center justify-center">
                      {percentage < passMark &&
                      secondAttempt &&
                      false &&
                      quizObj &&
                      Object.keys(quizObj).length ? (
                        <Failed2Dialog
                          open={true}
                          title={removeSequenceNumber(templateObj?.title || '')}
                          onClose={handleOnCloseFailed}
                        />
                      ) : isReview ? (
                        <ReviewDone quizStat={quizStat?.quizSummary} />
                      ) : (
                        quizObj &&
                        Object.keys(quizObj).length && (
                          <QuizScore
                            title={removeSequenceNumber(
                              template?.templateTitle || '',
                            )}
                            quizStat={quizStat?.quizSummary}
                            name={templateObj?.name}
                            showSecondFail={secondAttempt}
                            showScore={secondAttempt}
                            handleYesBtn={handleReview}
                            answeredCount={answeredCount}
                            totalMarks={quizObj?.questions?.length}
                            totalGems={totalGems}
                            resetPage={resetPage}
                            remainingQuiz={template?.remainingTemplate || 0}
                            handleSuccessPopup={handleSuccessPopup}
                            successPopup={successPopup}
                            failedPopup={failedPopup}
                          />
                        )
                      )}
                    </div>
                  </>
                )}

                {((!template?.message && !quizStat.quizCompleted) ||
                  (isReview && !template.message)) && (
                  <div
                    style={{
                      display: 'flex',
                      flex: 2,
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        padding: '1.5rem 2rem',
                      }}
                    >
                      <Card
                        sx={{
                          width: '100%',
                          // height: 'fit-content',
                          border: '3px solid rgba(76, 191, 167, 1)',

                          display: 'none',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 4,
                          mt: 3,
                          background: '#F8F8F8',
                          // background:"green",
                          boxShadow: '0px 4px 10px 0px #00000026',
                        }}
                      >
                        <Grid container>
                          <Grid item md={6}>
                            <Grid
                              container
                              sx={{
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <Grid item>
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    color: '#000',
                                  }}
                                >
                                  Per Question
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  sx={{
                                    fontWeight: 800,
                                    fontSize: '20px',
                                    color: '#C73E9D',
                                  }}
                                >
                                  {perQuestion} $G3MS
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={6}>
                            <Grid
                              container
                              sx={{
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <Grid item>
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    color: '#000',
                                  }}
                                >
                                  Total Earning
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  sx={{
                                    fontWeight: 800,
                                    fontSize: '20px',
                                    color: '#C73E9D',
                                  }}
                                >
                                  {user?.gemsTokens} $G3MS
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>

                      {/* <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: '22px',
                            color: '#000',
                            my: 5,
                          }}
                        >
                          Leaderboard
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            color: '#20A58A',
                            my: 5,
                          }}
                        >
                          View All{' '}
                        </Typography>
                      </Box> */}

                      {/* <Grid container direction="column" spacing={1}>
                              <Grid item>
                                <Box
                                  sx={{
                                    m: 2,
                                    p: 1,
                                    display: 'flex',
                                    direction: 'coulmn',
                                    justifyContent: 'space-evenly',
                                  }}
                                >
                                  <Box>
                                    <CircularProgressWithLabel
                                      timer
                                      size="5rem"
                                      progressColor="#4CBFA7"
                                      remainProgressColor="#E4F2EE"
                                      value={timer}
                                    />
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        textAlign: 'center',
                                      }}
                                      variant="subtitle1"
                                    >
                                      Time
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <CircularProgressWithLabel
                                      size="5rem"
                                      progressColor="#C73E9D"
                                      remainProgressColor="#FAEAF5"
                                      value={getG3MSPercentage()}
                                      label={user?.gemsTokens}
                                    />
                                    <Typography
                                      sx={{
                                        fontWeight: 700,
                                        textAlign: 'center',
                                      }}
                                      variant="subtitle1"
                                    >
                                      $G3MS
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid> */}

                      <Box>
                        {leaderBoard.map((obj, index) => (
                          <Card
                            key={index}
                            sx={{
                              width: '100%',
                              // height: 'fit-content',
                              border: '3px solid rgba(76, 191, 167, 1)',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: 4,
                              mt: 3,
                              background: '#F8F8F8',
                              boxShadow: '0px 4px 10px 0px #00000026',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 800,
                                  fontSize: '18px',
                                  color: '#333333',
                                  mr: 3,
                                }}
                              >
                                {index + 1}.
                              </Typography>
                              <Avatar
                                alt="John Doe"
                                sx={{ width: 50, height: 50 }}
                                src={
                                  obj?.student?.profileImage
                                    ? obj?.student?.profileImage
                                    : follower_profile
                                }
                              />
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: '#333333',
                                  }}
                                >
                                  @{obj?.student?.name || ''}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    color: '#666666',
                                  }}
                                >
                                  {obj?.student?.grade?.length
                                    ? `${obj?.student?.grade[0]?.grade?.name} grade`
                                    : ''}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 800,
                                  fontSize: '20px',
                                  color: '#C73E9D',
                                }}
                              >
                                {obj?.score} $G3MS
                              </Typography>
                            </Box>
                          </Card>
                        ))}
                      </Box>
                    </Box>
                  </div>
                )}
              </div>
              {((quizStat?.quizCompleted && !isReview) ||
                (isReview && template.message)) && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    border: '10px solid #C73E9D',
                    borderRadius: '13px',
                    flex: 1,
                    '&video': {
                      objectFit: 'cover !important',
                    },
                  }}
                  className="ml-6"
                >
                  <MuxPlayer
                    playbackId={handleVideo()}
                    className="dancing_video_player"
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'red',
                      objectFit: 'cover !important',
                    }}
                    autoplay={true}
                    onEnded={handleOnEnded}
                  />
                </Box>
              )}
            </div>

            {/* <Explantion
                template={template}
                templateObj={templateObj}
                removeSequenceNumber={removeSequenceNumber}
                currentQuestionData={currentQuestionData}
                onSubmit={handleReviewQuestion}
              /> */}

            {finalMarks?.complete && (
              <Box
                sx={{
                  border: '2px solid #20A58A',
                  backgroundColor: '#F8F8F8',
                  padding: '10px',
                  borderRadius: '12px',
                  mt: 6,
                }}
              >
                <Grid container>
                  <Grid item xs={4}>
                    <Grid
                      contaniner
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '24px',
                        color: '#000',
                      }}
                    >
                      <Grid item>
                        <Typography
                          sx={{
                            fontWeight: 800,
                            fontSize: '1rem',
                            color: '#000',
                          }}
                        >
                          You Now Have A Total Of
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            fontWeight: 800,
                            fontSize: '20px',
                            color: '#000',
                          }}
                        >
                          <span
                            style={{
                              color: '#C73E9D',
                              fontSize: '28px',
                              fontWeight: '700',
                            }}
                          >
                            {user?.gemsTokens}
                            $G3MS
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid contaniner>
                      {/* <Grid
                            item
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '24px',
                              color: '#000',
                            }}
                          >
                            {percentage >= 75 ? (
                              <Typography
                                sx={{
                                  fontWeight: 800,
                                  fontSize: '20px',
                                  color: '#000',
                                }}
                              >
                                You are now{' '}
                                <span
                                  style={{
                                    color: '#C73E9D',
                                    fontSize: '24px',
                                    fontWeight: '800',
                                  }}
                                >
                                  #3
                                </span>{' '}
                                on the
                                <span
                                  style={{
                                    color: '#C73E9D',
                                    fontSize: '24px',
                                    fontWeight: '800',
                                  }}
                                >
                                  Top 10 Score Squad !
                                </span>
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontWeight: 800,
                                  fontSize: '20px',
                                  color: '#000',
                                }}
                              >
                                Keep going you are almost in the
                                <span
                                  style={{
                                    color: '#C73E9D',
                                    fontSize: '24px',
                                    fontWeight: '800',
                                    marginLeft: '1ch',
                                  }}
                                >
                                  Top 10 Score Squad !
                                </span>
                              </Typography>
                            )}
                          </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid
                      contaniner
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Grid item>
                        <Button
                          size="small"
                          sx={{
                            textTransform: 'none',
                            width: '100%',
                            borderRadius: '8px',
                            fontWeight: 700,
                            fontSize: '20px',
                            background: '#C73E9D',
                            color: '#FFFFFF',
                            lineHeight: 1.715,
                            boxShadow: 3,
                            mt: 5,
                            '&.MuiButtonBase-root:hover': {
                              backgroundColor: '#C73E9D',
                            },
                          }}
                          fullWidth
                          onClick={() => {
                            navigate('/app/student/leaderboard/?topic=');
                          }}
                        >
                          Top 10 Score Squad
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        ) : (
          <EmptyQuiz width={30} />
          // <Quiz />
        )}
      </div>
      {showPopup && Object.keys(template)?.length && (
        <QuizWarning
          open={showPopup}
          handlePopupResponse={handlePopupResponse}
          onClose={handlePopupClose}
          videoTopic={videoTopic}
        />
      )}
      {showSuccess && (
        <SuccessAnswer
          isReview={isReview}
          open={showSuccess}
          handleSuccessDialog={handleSuccessDialog}
          onClose={onCloseSuccess}
          videoTopic={videoTopic}
        />
      )}
      {showReviewSuccess && secondTrial && (
        <ReviewSuccess
          open={showReviewSuccess}
          handleSuccessReviewDialog={handleSuccessReviewDialog}
          onClose={onCloseReviewSuccess}
          videoTopic={videoTopic}
        />
      )}
      {showWrong && !isReview && (
        <WrongAnswer
          isReview={isReview}
          replaceRaceNumber={replaceRaceNumber}
          correctAnswer={currentQuestionData}
          open={showWrong}
          handle={handleWrongDialog}
          onClose={() => onCloseWrong('')}
          videoTopic={videoTopic}
        />
      )}
      {getGemsOnReview && (
        <ReviewGetGems
          isReview={isReview}
          replaceRaceNumber={replaceRaceNumber}
          correctAnswer={currentQuestionData}
          open={showWrong}
          handle={handleWrongDialog}
          onClose={() => onCloseWrong('review')}
          videoTopic={videoTopic}
        />
      )}
      {showWrong && isReview && (
        <WrongAnswerReview
          isReview={isReview}
          replaceRaceNumber={replaceRaceNumber}
          correctAnswer={currentQuestionData}
          open={showWrong}
          handle={handleWrongDialog}
          onClose={onCloseReviewWrong}
          videoTopic={videoTopic}
        />
      )}
      {failed2Popup && (
        <Failed2Dialog
          failed2Popup={failed2Popup}
          title={removeSequenceNumber(templateObj?.title || '')}
          onClose={handleOnCloseFailed}
        />
      )}
    </div>
  );
}

export default DailyQuiz;
