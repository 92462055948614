import { apiSlice } from '..';

export const paymentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query({
      query: () => {
        return {
          url: 'payment-subscription/plans?planCategory=B2C',
          method: 'GET',
        };
      },
    }),
    approveOrRejectChild: builder.mutation({
      query: ({ confirmationId, confirmationStatus }) => {
        return {
          url: '/auth/update-parent-child-confirmation-status',
          method: 'POST',
          body: {
            confirmationId,
            confirmationStatus,
          },
        };
      },
    }),
    iniTiatePayment: builder.mutation({
      query: ({ items, userId }) => {
        return {
          url: '/payment-subscription/create-checkout-session',
          method: 'POST',
          body: {
            lineItems: items || [],
            userId: userId,
            clientCancelUrl: `${window.location.href}`,
          },
        };
      },
    }),
    getSessionDetails: builder.query({
      query: (sessionId) => {
        return {
          url: `payment-subscription/get-checkout-session/${sessionId}`,
          method: 'GET',
        };
      },
    }),
    getDetailsByPaymentConfirmationId: builder.query({
      query: (confirmationId) => {
        return {
          url: `/auth/get-parent-confirmation-details/${confirmationId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useApproveOrRejectChildMutation,
  useIniTiatePaymentMutation,
  useGetSessionDetailsQuery,
  useGetDetailsByPaymentConfirmationIdQuery,
} = paymentApi;
