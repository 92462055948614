import React, { useEffect, useState } from 'react';

import AuthDetails from 'components/AuthDetails';
import AuthPage from 'components/AuthPage';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useGetGradeListQuery } from 'store/apis/hometab';
import {
  useCompleteProfileMutation,
  useGetMyProfileQuery,
} from 'store/apis/profile';
import { useGetSchoolsQuery } from 'store/apis/school';
import {
  useGetCitiesQuery,
  useGetStatesQuery,
  useGetUserTypesQuery,
} from 'store/apis/states-cities';

import { auth } from 'api/firebase';
import Input from 'components/Input';
import { containsOnlyAllowedCharacters } from 'utils/lib';
import * as Yup from 'yup';

import { Loader, Radio, Select } from '@mantine/core';
import LoadingOverlay from 'app/components/LoadingOverlay';
import Button from 'components/Button';
import { ChevronDown } from 'mdi-material-ui';
import cn from 'utils/cn';

/* const roleMap = [
  {
    value: 'student',
    label: 'Student',
  },
  {
    value: 'teacher',
    label: 'Teacher',
  },
  {
    value: 'parent',
    label: 'Parent',
  },
  {
    value: 'school_leader',
    label: 'School Leader',
  },
  {
    value: 'district_leader',
    label: 'District Leader',
  },
  {
    value: 'tutor_company',
    label: 'Tutor Company',
  },
  {
    value: 'tutor',
    label: 'Tutor',
  },
  {
    value: 'other',
    label: 'Other',
  },
]; */
const referralSourceList = [
  {
    value: 'Tiktok',
    label: 'Tiktok',
  },
  {
    value: 'Instagram',
    label: 'Instagram',
  },
  {
    value: 'Facebook',
    label: 'Facebook',
  },
  {
    value: 'Twitter',
    label: 'Twitter',
  },
  {
    value: 'Google',
    label: 'Google',
  },
  {
    value: 'Friend',
    label: 'Friend',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  role: Yup.object().shape({
    value: Yup.string().required('Role is required'),
  }),
  referralSource: Yup.object().shape({
    value: Yup.string().required('Referral source is required'),
  }),
  // grade: Yup.string().when('role', {
  //   is: 'student',
  //   then: schema => schema.required('Grade is required'),
  // }),
  // username: Yup.string().when('role', {
  //   is: 'student',
  //   then: schema => schema.required('Username is required'),
  // }),
  // state: Yup.string().when('haveSchoolCode', {
  //   is: 'No',
  //   then: schema => schema.required('State is required'),
  // }),
  // city: Yup.string().when('haveSchoolCode', {
  //   is: 'No',
  //   then: schema => schema.required('City is required'),
  // }),
  // school: Yup.string().when('haveSchoolCode', {
  //   is: 'No',
  //   then: schema => schema.required('School is required'),
  // }),
});

export default function CompleteProfile({ setStep }) {
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);

  const [showRole, setShowRole] = useState(true);
  const { openModal } = useModal();

  const {
    data: myProfile,
    isLoading: myProfileLoading,
    error: myProfileError,
  } = useGetMyProfileQuery();

  const {
    data: roleData,
    isLoading: roleLoading,
    error: roleError,
  } = useGetUserTypesQuery();

  const roleMap = React.useMemo(() => {
    if (!roleData?.data) return [];
    return roleData?.data?.map((role) => ({
      value: role,
      label: role
        ?.replace(/_/g, ' ')
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
    }));
  }, [roleData]);

  // console.log('myProfile', myProfile);

  const {
    data: gradeList,
    isLoading: gradeLoading,
    error,
  } = useGetGradeListQuery();

  const [completeProfile, completeProfileRes] = useCompleteProfileMutation();

  const [createdNewSchool, setCreatedNewSchool] = useState(false);

  const formik = useFormik({
    initialValues: {
      haveSchoolCode: '',
      firstName: '',
      lastName: '',
      username: '',
      role: {
        label: '',
        value: '',
      },
      referralSource: {
        label: '',
        value: '',
      },
      schoolCode: '',
      grade: {
        label: '',
        value: '',
      },
      dob: '',
      state: {
        label: '',
        value: '',
      },
      city: {
        label: '',
        value: '',
      },
      school: {
        label: '',
        value: '',
      },
      gradeText: '',
    },
    onSubmit: async (values, { setFieldError }) => {
      try {
        console.log('values', values);

        if (values?.role?.value === 'student' && !values.username) {
          openModal('WarningModal', {
            title: 'Failed to complete profile',
            description: 'Username is required',
          });
          setFieldError('username', 'Username is required');
          return;
        }

        if (values?.role?.value === 'student' && !values.grade.value) {
          openModal('WarningModal', {
            title: 'Failed to complete profile',
            description: 'Grade is required',
          });
          setFieldError('grade', 'Grade is required');
          return;
        }

        const body = {
          firstName: values.firstName,
          lastName: values.lastName,
          role: values.role.value,
          username: values.username,
          schoolId: values.school?.value || values.schoolCode,
          createdNewSchool,
          referralSource: values.referralSource.value,
          dateOfBirth: values.dob,
          gradeId: values.grade.value,
          stateId: values.state.value,
          cityId: values.city.value,
        };

        const dataWithoutEmpty = Object.keys(body).reduce((acc, key) => {
          if (body[key] !== '') {
            acc[key] = body[key];
          }
          return acc;
        }, {});

        const response = await completeProfile(dataWithoutEmpty).unwrap();

        // if (values.role !== 'student') {
        //   dispatch(setShowGoToWeb(true));

        //   return;
        // }

        const data = response.data;

        const user = data?.user || data?.data?.user;
        const accessToken = auth?.currentUser?.getIdToken();

        if (
          user.type === 'student' ||
          user.type === 'teacher' ||
          user.type === 'tutor'
        ) {
          navigate('/select-avatar');
        } else {
          navigate('/upload-avatar');
        }

        // navigation.navigate('BottomTabScreen');
      } catch (err) {
        console.log('err', err);
        const errorMessage1 =
          err?.data?.data?.message || err?.data?.error || err?.data?.message;

        openModal('WarningModal', {
          title: 'Failed to complete profile',
          description: errorMessage1,
        });
      }
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validate(values) {
      const errors = {};
      if (values.haveSchoolCode === 'No') {
        if (!values.state?.value) {
          errors.state = 'State is required';
        }
        if (!values.city?.value) {
          errors.city = 'City is required';
        }
        if (!values.school?.value) {
          errors.school = 'School is required';
        }
      }

      if (values.role.value?.toLowerCase() === 'student') {
        if (!values.username) {
          errors.username = 'Username is required';
        }
        if (!containsOnlyAllowedCharacters(values.username)) {
          errors.username =
            'Username can only contain letters, numbers, spaces, hyphens, and underscores';
        }

        if (!values.grade?.value) {
          errors.grade = 'Grade is required';
        }
      }

      if (values.haveSchoolCode === 'Yes' && !createdNewSchool) {
        if (!values.schoolCode) {
          errors.schoolCode = 'School code is required';
        }
      }

      if (
        !['homeschool_parent_esa', 'parent']?.includes(values.role.value) &&
        !values.haveSchoolCode
      ) {
        errors.haveSchoolCode = 'School code is required';
      }

      return errors;
    },
  });

  const {
    data: statesData,
    isLoading: statesLoading,
    error: statesError,
    isFetching: statesFetching,
  } = useGetStatesQuery({
    search: '',
  });

  const {
    data: citiesData,
    isLoading: citiesLoading,
    error: citiesError,
    isFetching: citiesFetching,
  } = useGetCitiesQuery(
    {
      search: '',
      stateId: formik.values.state?.value,
    },
    {
      skip:
        formik.values.state?.value === '' ||
        formik.values.state?.value === undefined,
    },
  );

  const {
    data: schoolsData,
    isLoading: schoolsLoading,
    isFetching: schoolsFetching,
    error: schoolsError,
  } = useGetSchoolsQuery(
    {
      search: '',
      stateId: formik.values.state?.value,
      cityId: formik.values.city?.value,
    },
    {
      skip:
        formik.values.city?.value === '' ||
        formik.values.city?.value === undefined,
    },
  );

  const allCities = React.useMemo(() => {
    if (!citiesData) return [];

    return citiesData.data.map((city) => ({
      value: city.id,
      label: city.name,
    }));
  }, [citiesData]);

  const allStates = React.useMemo(() => {
    if (!statesData) return [];

    return statesData.data.map((state) => ({
      value: state.id,
      label: state.name,
    }));
  }, [statesData]);

  const allSchools = React.useMemo(() => {
    if (!schoolsData) return [];

    return schoolsData.data?.items?.map((school) => ({
      value: school.id,
      label: school.name,
    }));
  }, [schoolsData]);

  const gradeListData = React.useMemo(() => {
    if (!gradeList) return [];

    return gradeList.data.map((grade) => ({
      value: grade.id,
      label: grade.name,
    }));
  }, [gradeList]);

  useEffect(() => {
    const dob = searchParams.get('birthDate');
    console.log('dob', dob);
    if (dob) {
      formik.setFieldValue('dob', dob);

      const isUnder13 = dayjs().diff(dob, 'year') < 13;

      if (isUnder13 && roleMap?.length > 0) {
        formik.setFieldValue(
          'role',
          roleMap?.find((role) => role.value === 'student'),
        );
        setShowRole(false);
      }
    }
    formik.setFieldValue('firstName', searchParams.get('childFirstName'));
    formik.setFieldValue('lastName', searchParams.get('childLastName'));
    formik.setFieldValue('username', searchParams.get('username'));

    formik.setFieldValue('referralSource', {
      value: searchParams.get('referralSource'),
      label: searchParams.get('referralSource'),
    });
  }, [roleMap]);

  useEffect(() => {
    const dob = searchParams.get('birthDate');
    if (myProfile?.data?.type === 'student' && !dob && roleMap?.length > 0) {
      formik.setFieldValue(
        'role',
        roleMap.find((role) => role.value === 'student'),
      );
      formik.setFieldValue('username', myProfile?.data?.username);
      setShowRole(false);
    } else {
      if (roleMap?.length > 0) {
        const role = roleMap.find(
          (role) => role.value === myProfile?.data?.type,
        );
        if (role && role?.value !== 'other') {
          formik.setFieldValue('role', role);
          if (!['homeschool_parent_esa', 'parent']?.includes(role?.value)) {
            setShowRole(false);
          }
        }
      }
    }

    if (!searchParams.get('childFirstName')) {
      formik.setFieldValue('firstName', myProfile?.data?.name);
    }
    if (!searchParams.get('childLastName')) {
      formik.setFieldValue('lastName', myProfile?.data?.lastname);
    }
  }, [myProfile, roleMap]);

  const loader =
    gradeLoading ||
    statesLoading ||
    citiesLoading ||
    schoolsLoading ||
    completeProfileRes.isLoading;

  const username = searchParams.get('username') || myProfile?.data?.username;
  const role = formik.values.role?.value || myProfile?.data?.type;

  const showRoleField = myProfile?.data?.type !== 'student' && showRole;

  console.log(myProfile?.data?.type, gradeListData);

  return (
    <AuthPage className="gap-4">
      <Logo className="w-32 mb-4 content-center" />
      <div className="grid gap-4">
        <AuthDetails.Title>Tell us more about you!</AuthDetails.Title>
        <form
          onSubmit={formik.handleSubmit}
          className="grid gap-4 content-center"
        >
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <Input
              label={'First Name'}
              placeholder={'Enter first name'}
              error={formik.touched.firstName && formik.errors.firstName}
              {...formik.getFieldProps('firstName')}
            />

            <Input
              label={'Last Name'}
              placeholder={'Enter last name'}
              error={formik.touched.lastName && formik.errors.lastName}
              {...formik.getFieldProps('lastName')}
            />
          </div>

          {showRoleField && (
            <Select
              classNames={{
                label:
                  '!block !font-medium !text-[#484848] !text-base xl:!text-lg',
                input:
                  'block w-full px-3 xl:!h-14 !py-3 text-base xl:text-lg border focus:border  border-[#DCDCDC] !rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center focus:border-[#C73E9D] focus:ring-1 focus:ring-[#C73E9D] transition-all duration-200 ease-in-out',
                error: '!border-[#F41F52] focus:border-[#F41F52]',
                section: '!space-y-4',
                root: '!space-y-2',
              }}
              size="lg"
              label={'Role'}
              placeholder={'Select role'}
              error={
                formik.touched.role && formik.errors.role && 'Role is required'
              }
              value={formik.values?.role?.value}
              data={roleMap}
              onChange={(value) => {
                const option = roleMap.find((role) => role.value === value);
                formik.setFieldValue('role', option);
              }}
              rightSection={<ChevronDown />}
              disabled={roleLoading}
            />
          )}

          {role?.toLowerCase() === 'student' && !username && (
            <div
              className={cn('grid gap-4 grid-cols-1 md:grid-cols-2', {
                'md:grid-cols-1': username,
              })}
            >
              {!username && (
                <Input
                  label={'Username'}
                  placeholder={'Enter username'}
                  error={formik.touched.username && formik.errors.username}
                  {...formik.getFieldProps('username')}
                />
              )}
            </div>
          )}

          {myProfile?.data?.type === 'student' ||
          formik.values.role?.value?.toLocaleLowerCase() === 'student' ? (
            <Select
              classNames={{
                label:
                  '!block !font-medium !text-[#484848] !text-base xl:!text-lg',
                input:
                  'block w-full px-3 xl:!h-14 !py-3 text-base xl:text-lg border focus:border  border-[#DCDCDC] !rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center focus:border-[#C73E9D] focus:ring-1 focus:ring-[#C73E9D] transition-all duration-200 ease-in-out',
                error: '!border-[#F41F52] focus:border-[#F41F52]',
                section: '!space-y-4',
                root: '!space-y-2',
              }}
              size="lg"
              label={'Grade'}
              placeholder={'What grade are you currently in?'}
              error={formik.touched.grade && formik.errors.grade}
              value={formik.values?.grade?.value}
              data={gradeListData}
              onChange={(value) => {
                const option = gradeListData?.find(
                  (grade) => grade.value === value,
                );
                formik.setFieldValue('grade', option);
              }}
              rightSection={
                gradeLoading ? (
                  <Loader size="xs" color="blue" />
                ) : (
                  <ChevronDown />
                )
              }
              disabled={gradeLoading}
              comboboxProps={{ withinPortal: false }}
            />
          ) : null}

          <Select
            classNames={{
              label:
                '!block !font-medium !text-[#484848] !text-base xl:!text-lg',
              input:
                'block w-full px-3 xl:!h-14 !py-3 text-base xl:text-lg border focus:border  border-[#DCDCDC] !rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center focus:border-[#C73E9D] focus:ring-1 focus:ring-[#C73E9D] transition-all duration-200 ease-in-out',
              error: '!border-[#F41F52] focus:border-[#F41F52]',
              section: '!space-y-4',
              root: '!space-y-2',
            }}
            size="lg"
            label={'Referral Source'}
            placeholder={'How did you hear about us?'}
            error={
              formik.touched.referralSource && formik.errors.referralSource
            }
            value={formik.values?.referralSource?.value}
            data={referralSourceList}
            onChange={(value) => {
              const option = referralSourceList.find(
                (referral) => referral.value === value,
              );
              formik.setFieldValue('referralSource', option);
            }}
            rightSection={<ChevronDown />}
          />

          {formik.values.role?.value !== '' &&
            !['homeschool_parent_esa', 'parent']?.includes(
              formik.values.role?.value,
            ) && (
              <>
                <Radio.Group
                  value={formik.values.haveSchoolCode}
                  onChange={(value) => {
                    formik.setFieldValue('haveSchoolCode', value);
                  }}
                  name="haveSchoolCode"
                  label="Do you have a school code?"
                  classNames={{
                    label:
                      '!block !font-medium !text-[#484848] !text-base xl:!text-lg',
                    input:
                      'block w-full px-3 xl:!h-14 !py-3 text-base xl:text-lg border focus:border  border-[#DCDCDC] !rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center focus:border-[#C73E9D] focus:ring-1 focus:ring-[#C73E9D] transition-all duration-200 ease-in-out',
                    root: '!space-y-2 ',
                  }}
                >
                  <div className="flex gap-8 mt-2">
                    <Radio
                      value="Yes"
                      label="Yes"
                      classNames={{
                        label:
                          '!block !font-medium !text-[#484848] !text-base xl:!text-lg',
                        body: 'items-center justify-center flex',
                      }}
                    />
                    <Radio
                      value="No"
                      label="No"
                      classNames={{
                        label:
                          '!block !font-medium !text-[#484848] !text-base xl:!text-lg',
                        body: 'items-center justify-center flex',
                      }}
                    />
                  </div>
                </Radio.Group>
                {formik.values.haveSchoolCode === 'No' && (
                  <div className="max-sm:space-y-4 sm:grid sm:gap-4 sm:grid-cols-1 md:grid-cols-2">
                    <Select
                      classNames={{
                        label:
                          '!block !font-medium !text-[#484848] !text-base xl:!text-lg w-full',
                        input:
                          'block w-full px-3 xl:!h-14 !py-3 text-base xl:text-lg border focus:border  border-[#DCDCDC] !rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center focus:border-[#C73E9D] focus:ring-1 focus:ring-[#C73E9D] transition-all duration-200 ease-in-out',
                        error: '!border-[#F41F52] focus:border-[#F41F52]',
                        section: '!space-y-4',
                        root: '!space-y-2',
                      }}
                      size="lg"
                      label={'State'}
                      placeholder={'Select state'}
                      error={formik.touched.state && formik.errors.state}
                      value={formik.values?.state?.value}
                      data={allStates}
                      onChange={(value) => {
                        const option = allStates.find(
                          (state) => state.value === value,
                        );
                        formik.setFieldValue('state', option);
                        formik.setFieldValue('city', {});
                        formik.setFieldValue('school', {});
                      }}
                      rightSection={
                        statesFetching ? (
                          <Loader size="xs" color="blue" className="ml-2" />
                        ) : (
                          <ChevronDown />
                        )
                      }
                      disabled={statesLoading}
                    />
                    {allCities?.length > 0 && (
                      <Select
                        classNames={{
                          label:
                            '!block !font-medium !text-[#484848] !text-base xl:!text-lg',
                          input:
                            'block w-full px-3 xl:!h-14 !py-3 text-base xl:text-lg border focus:border  border-[#DCDCDC] !rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center focus:border-[#C73E9D] focus:ring-1 focus:ring-[#C73E9D] transition-all duration-200 ease-in-out',
                          error: '!border-[#F41F52] focus:border-[#F41F52]',
                          section: '!space-y-4',
                          root: '!space-y-2',
                        }}
                        size="lg"
                        label={'City'}
                        placeholder={'Select city'}
                        error={formik.touched.city && formik.errors.city}
                        value={formik.values?.city?.value}
                        data={allCities}
                        key={formik.values.state?.value || 'city'}
                        onChange={(value) => {
                          const option = allCities.find(
                            (city) => city.value === value,
                          );
                          formik.setFieldValue('city', option);
                          formik.setFieldValue('school', {});
                        }}
                        rightSection={
                          citiesFetching ? (
                            <Loader size="xs" color="blue" className="ml-2" />
                          ) : (
                            <ChevronDown />
                          )
                        }
                        disabled={
                          citiesLoading || citiesFetching || statesFetching
                        }
                      />
                    )}

                    {allSchools?.length > 0 && (
                      <Select
                        classNames={{
                          label:
                            '!block !font-medium !text-[#484848] !text-base xl:!text-lg',
                          input:
                            'block w-full px-3 xl:!h-14 !py-3 text-base xl:text-lg border focus:border  border-[#DCDCDC] !rounded-lg shadow-sm lg:text-base focus:outline-none appearance-none justify-center focus:border-[#C73E9D] focus:ring-1 focus:ring-[#C73E9D] transition-all duration-200 ease-in-out',
                          error: '!border-[#F41F52] focus:border-[#F41F52]',
                          section: '!space-y-4',
                          root: '!space-y-2 col-span-2',
                        }}
                        size="lg"
                        label={'School'}
                        placeholder={'Select school'}
                        error={formik.touched.school && formik.errors.school}
                        value={formik.values?.school?.value}
                        data={allSchools}
                        key={formik.values.city?.value || 'school'}
                        onChange={(value) => {
                          const option = allSchools.find(
                            (school) => school.value === value,
                          );
                          formik.setFieldValue('school', option);
                        }}
                        rightSection={
                          schoolsFetching ? (
                            <Loader size="xs" color="blue" className="ml-2" />
                          ) : (
                            <ChevronDown />
                          )
                        }
                        disabled={
                          schoolsLoading || schoolsFetching || citiesFetching
                        }
                      />
                    )}
                  </div>
                )}

                {formik.values.haveSchoolCode === 'Yes' && (
                  <>
                    <Input
                      label={
                        <div className="flex justify-between items-center flex-wrap gap-4">
                          <span className="!block !font-medium !text-[#484848] !text-base xl:!text-lg">
                            School Code
                          </span>
                          <button
                            className="text-[#C44297] font-medium text-base xl:text-lg underline text-left flex justify-start items-start"
                            type="button"
                            onClick={() =>
                              openModal('AddSchoolModal', {
                                onSuccess: (school) => {
                                  setCreatedNewSchool(true);
                                },
                              })
                            }
                          >
                            My School/Company is not Listed
                          </button>
                        </div>
                      }
                      placeholder={'Enter school code'}
                      error={
                        formik.touched.schoolCode && formik.errors.schoolCode
                      }
                      {...formik.getFieldProps('schoolCode')}
                    />
                  </>
                )}
              </>
            )}

          <Button
            className="max-w-none w-full mt-4"
            type="submit"
            variant="primary"
            isLoading={loader}
          >
            Continue
          </Button>
        </form>
      </div>
      <LoadingOverlay
        isLoading={myProfileLoading || loader || statesFetching}
      />
    </AuthPage>
  );
}
