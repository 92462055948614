import React, { useEffect } from 'react';

import { auth } from 'api/firebase';
import AuthPage from 'components/AuthPage';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import analytics from 'utils/analytics';

import AuthDetails from 'components/AuthDetails';
import Button from 'components/Button';
import Input from 'components/Input';
import Logo from 'components/Logo';
import useModal from 'components/Modal/hook/useModal';
import SocialLogin from 'components/SocialLogin';
import { useFormik } from 'formik';
import useFirebaseLogin from 'hooks/useFirebaseLogin';
import {
  useRegisterFirebaseUserMutation,
  useRegisterOtherUserMutation,
} from 'store/apis/auth';
import { logoutUser, setAccessToken } from 'store/Slice/authSlice';
import { selectTempUser } from 'store/Slice/uiSlice';

import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string(),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string().required('Required'),
});

export default function Over13SignupScreen() {
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);

  const phoneNumber = params.get('phone');
  const phone = phoneNumber?.includes('+') ? phoneNumber : `+${phoneNumber}`;

  const dateOfBirth = params.get('birthDate');

  const [registerUser, registerUserRes] = useRegisterOtherUserMutation();
  const [registerFirebaseUser, registerFirebaseUserResponse] =
    useRegisterFirebaseUserMutation();

  const { openModal } = useModal();

  const [loader, setLoader] = useState(false);

  const {
    loginWithFirebase,
    isLoading: firebaseLoading,
    checkProfileAndNavigate,
  } = useFirebaseLogin();

  const dispatch = useDispatch();

  const tempUser = useSelector(selectTempUser);

  const nextNavigation = () => {
    openModal('AccountCreatedModal', {
      onContinue: () => {
        navigate('/complete-profile', {
          replace: true,
        });
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        auth?.signOut();
        dispatch(logoutUser());
        localStorage.clear();
        const data = {
          email: values.email,
          phone: values.phone?.replace(/\s/g, ''),
          password: values.password,
          confirmPassword: values.confirmPassword,
          dateOfBirth: dateOfBirth,
          tempUserId: tempUser?.id,
        };

        const res = await registerUser(data).unwrap();
        console.log('registerUser', res);
        analytics.logEvent('register_form_submission', {
          email: values.email,
          phone: values.phone,
        });
        if (res.data.customToken) {
          await auth?.signInWithCustomToken(res.data.customToken);
          const accessToken = await auth?.currentUser?.getIdToken();
          if (accessToken) {
            checkProfileAndNavigate(res.data.user, accessToken, nextNavigation);
          } else {
            openModal('WarningModal', {
              title: 'Login Failed',
              description: 'Failed to get access token.',
            });
          }
        } else {
          openModal('WarningModal', {
            title: 'Login Failed',
            description: 'Failed to get access token.',
          });
        }
      } catch (err) {
        console.log('err', err);
        const message = Array.isArray(err?.data?.message)
          ? err?.data?.message[0]
          : err?.data?.message;

        openModal('WarningModal', {
          title: 'Failed to register user',
          description: message || 'Something went wrong, please try again.',
          // onContinue: () => {
          //   if (message === 'Username is already taken') {
          //     navigate('/login');
          //   }
          // },
        });
      }
    },
  });

  const socialAuthCallback = async ({ success }) => {
    setLoader(false);
    if (success) {
      const accessToken = await auth?.currentUser?.getIdToken();
      if (accessToken) {
        dispatch(setAccessToken(accessToken));

        try {
          const res = await registerFirebaseUser({
            firebaseAccessToken: accessToken,
            dateOfBirth: dateOfBirth,
            phone: phone,
            tempUserId: tempUser?.id,
          }).unwrap();
          checkProfileAndNavigate(res.data.user, accessToken, nextNavigation);
        } catch (err) {
          if (
            err?.data?.data === 'USER_ALREADY_REGISTERED' ||
            err?.data?.code === 'USER_ALREADY_REGISTERED' ||
            err?.data?.error === 'USER_ALREADY_REGISTERED'
          ) {
            await loginWithFirebase();
            return;
          }

          openModal('WarningModal', {
            title: 'Failed to register user',
            description: err?.data?.message,
          });
        }
      } else {
        openModal('WarningModal', {
          title: 'Failed to get access token',
        });
      }
    }
  };

  useEffect(() => {
    if (phone) {
      formik.setFieldValue('phone', phone);
    }
  }, [phone]);

  return (
    <AuthPage className="gap-4">
      <Logo className="w-32 mb-4" />
      <AuthDetails>
        <AuthDetails.TwoText
          leftText="Already have an account?"
          rightText="Login"
          rightTextUrl="/login"
        />
        <AuthDetails.Title>Create a G3MS Account!</AuthDetails.Title>
        <AuthDetails.Description>
          Give your students the support they need to close learning gaps.
        </AuthDetails.Description>
        <Button className="mx-auto" onClick={() => navigate('/qr')}>
          Scan QR Code
        </Button>
      </AuthDetails>

      <form className="grid gap-3 w-full" onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Enter your email address"
          error={formik.touched.email && formik.errors.email}
          label={'Email'}
          {...formik.getFieldProps('email')}
        />
        <div className="grid gap-y-4 gap-x-3 lg:grid-cols-2">
          <Input
            placeholder="********"
            type={'password'}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password}
            value={formik.values.password}
            label="Password"
          />
          <Input
            placeholder="********"
            type={'password'}
            name="confirmPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            value={formik.values.confirmPassword}
            label="Confirm Password"
          />
        </div>

        <Button
          variant={'primary'}
          type="submit"
          isLoading={
            loader ||
            registerUserRes.isLoading ||
            firebaseLoading ||
            registerFirebaseUserResponse.isLoading
          }
          className="max-w-[20rem] w-full mx-auto mt-2"
        >
          Sign Up
        </Button>
      </form>
      <SocialLogin callback={socialAuthCallback} setIsLoading={setLoader} />
    </AuthPage>
  );
}
